import { Controller } from 'stimulus'
import {
  clearClonedDataSearchSelectElement,
  overwriteCloneSearchSelectElement,
} from 'controllers/doctor_profiles/utils/nested_form'

export default class extends Controller {
  static targets = ['append_elem', 'add_elem', 'destroy_button']

  connect() {
    if (this.add_elemTargets.length > 1) {
      this.destroy_buttonTargets.forEach((element) => {
        element.style.display = 'flex'
      })
    }
  }

  addAssociation(event) {
    event.preventDefault()

    const clonedAddItem = this.add_elemTarget.cloneNode(true)

    Array.from(clonedAddItem.getElementsByClassName('c-form-input--error')).forEach((element) => {
      element.classList.remove('c-form-input--error')
    })
    Array.from(clonedAddItem.getElementsByClassName('c-form-input--error__message')).forEach((element) => {
      element.remove()
    })
    if (clonedAddItem.getElementsByClassName('p-doctor-profile-edit__box__form__part--row__error').length) {
      clonedAddItem.getElementsByClassName('p-doctor-profile-edit__box__form__part--row__error')[0].remove()
    }
    clonedAddItem.querySelectorAll('[data-doctor-profiles--societies-input]').forEach((element) => {
      overwriteCloneSearchSelectElement(element)
    })

    const eventTypeParam = event.target.getAttribute('data-doctor-profiles--societies-type-param')
    if (eventTypeParam == 'society') {
      const societyCertificationElement = clonedAddItem.querySelector('[data-controller="doctor-profiles--societies"]')
      societyCertificationElement.querySelector('[data-doctor-profiles--societies-button="button"]').style.display =
        'none'
      societyCertificationElement.querySelector('[data-doctor-profiles--societies-button="span"]').style.display =
        'block'

      const societyCertificationItemElements = societyCertificationElement.querySelectorAll(
        '[data-doctor-profiles--societies-target="add_elem"]'
      )

      societyCertificationItemElements.forEach((element, index) => {
        const selectElement = element.querySelector('[data-doctor-profiles--societies-input="society_certification"]')
        selectElement.disabled = true
        if (index != 0) {
          element.remove()
        }
        // 複製元の資格・役職などが複数あった場合に、削除ボタンが表示されてしまうため明示的に非表示にする
        if (index === 0) {
          societyCertificationElement.querySelector(
            '[data-doctor-profiles--societies-target="destroy_button"]'
          ).style.display = 'none'
        }
      })
    }

    this.append_elemTarget.appendChild(clonedAddItem)

    if (this.add_elemTargets.length > 1) {
      this.destroy_buttonTargets.forEach((element) => {
        element.style.display = 'flex'
      })
    }
  }

  removeAssociation(event) {
    event.preventDefault()

    event.target.closest('[data-doctor-profiles--societies-target="add_elem"]').remove()

    if (this.add_elemTargets.length == 1) {
      this.destroy_buttonTargets.forEach((element) => {
        element.style.display = 'none'
      })
    }
  }

  createSocietyCertificationSelectBox(searchSelectEvent) {
    const event = searchSelectEvent.detail
    const selectedValue = event.target.value

    const societyCertificationElement = event.target
      .closest('[data-doctor-profiles--societies-target="add_elem"]')
      .querySelector('[data-controller="doctor-profiles--societies"]')

    const appendElem = event.target
      .closest('[data-doctor-profiles--societies-target="add_elem"]')
      .querySelector('[data-doctor-profiles--societies-target="append_elem"]')
    const clonedAddItem = appendElem
      .querySelector('[data-doctor-profiles--societies-target="add_elem"]')
      .cloneNode(true)
    const clonedAddItemSelectBoxElement = clonedAddItem.querySelector('[data-doctor-profiles--societies-input]')

    overwriteCloneSearchSelectElement(clonedAddItemSelectBoxElement, { clearOptions: true })
    clearClonedDataSearchSelectElement(clonedAddItemSelectBoxElement)

    clonedAddItemSelectBoxElement.disabled = false
    societyCertificationElement.querySelector('[data-doctor-profiles--societies-button="button"]').style.display =
      'block'
    societyCertificationElement.querySelector('[data-doctor-profiles--societies-button="span"]').style.display = 'none'
    if (selectedValue == '') {
      clonedAddItemSelectBoxElement.disabled = true

      societyCertificationElement.querySelector('[data-doctor-profiles--societies-button="button"]').style.display =
        'none'
      societyCertificationElement.querySelector('[data-doctor-profiles--societies-button="span"]').style.display =
        'block'

      appendElem.querySelectorAll('[data-doctor-profiles--societies-target="add_elem"]').forEach((element) => {
        element.remove()
      })

      appendElem.appendChild(clonedAddItem)
    } else {
      this.getSocietyCertifications(selectedValue)
        .then((response) => {
          return response.json()
        })
        .then((json) => {
          json.forEach((value) => {
            const option = document.createElement('option')
            option.value = value['id']
            option.text = value['name']
            clonedAddItemSelectBoxElement.add(option)
          })

          appendElem.querySelectorAll('[data-doctor-profiles--societies-target="add_elem"]').forEach((element) => {
            element.remove()
          })

          appendElem.appendChild(clonedAddItem)
        })
        .catch(() => {})
    }
  }

  getSocietyCertifications(societyId) {
    const url = '/private/api/internal/v1/doctor_profile/suggest/candy_society_certifications?society_id=' + societyId

    return fetch(url)
  }
}
