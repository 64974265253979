import { Controller } from 'stimulus'
import * as bootstrap from 'bootstrap'

export default class extends Controller {
  static targets = ['show_elem']

  connect() {
    new bootstrap.Modal(this.show_elemTarget).show()
  }
}
