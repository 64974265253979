import { Controller } from 'stimulus'

/**
 * admin 医師アカウント編集画面のコントローラ
 * FYI: app/views/admin/users/doctors/edit.html.slim
 */
export default class extends Controller {
  static targets = ['doctorKind', 'openYear']

  connect() {
    this.toggleOpenYear()
  }

  /**
   * 開業年の活性/非活性
   */
  toggleOpenYear() {
    if (!this.hasDoctorKindTarget) {
      // 詳細情報の入力がない場合
      return
    }
    if ($(this.doctorKindTarget).val() == 'employee') {
      this.openYearTarget.hidden = true
    } else {
      this.openYearTarget.hidden = false
    }
  }
}
