import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['phoneNumberArea', 'urlArea', 'phoneRadioButton', 'urlRadioButton']

  connect() {
    this.phoneNumberArea = $(this.phoneNumberAreaTarget)
    this.urlArea = $(this.urlAreaTarget)
    this.phoneRadioButton = $(this.phoneRadioButtonTarget)
    this.urlRadioButton = $(this.urlRadioButtonTarget)

    this.changeMeanType()
  }

  changeMeanType() {
    this.togglePhoneNumber()
    this.toggleUrl()
  }

  /**
   * 電話番号入力欄の表示/非表示
   */
  togglePhoneNumber() {
    const checked = this.phoneRadioButton.prop('checked')
    if (checked) {
      this.phoneNumberArea.prop('disabled', false)
      this.phoneNumberArea.show()
    } else {
      this.phoneNumberArea.prop('disabled', true)
      this.phoneNumberArea.hide()
    }
  }

  /**
   * URL入力欄の表示/非表示
   */
  toggleUrl() {
    const checked = this.urlRadioButton.prop('checked')
    if (checked) {
      this.urlArea.prop('disabled', false)
      this.urlArea.show()
    } else {
      this.urlArea.prop('disabled', true)
      this.urlArea.hide()
    }
  }
}
