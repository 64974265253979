import $ from 'jquery'

// nested-form-type: search-select (select2を使用したdropdown補完)
export function overwriteCloneSearchSelectElement(element, { clearOptions = false } = {}) {
  element.id = ''
  element.tabindex = ''
  // 選択してくださいを初期選択とする
  element.selectedIndex = 0
  if (clearOptions) {
    for (let i = element.options.length - 1; i >= 0; i--) {
      if (element.options.item(i).value != '') {
        element.removeChild(element.options.item(i))
      }
    }
  }
}

export function clearClonedDataSearchSelectElement(clonedAaddElem) {
  // select2というセレクトボックスをカスタマイズできるlibraryの影響により、普通にcloneができない
  clonedAaddElem.querySelectorAll('[data-select2-id]').forEach((element) => element.removeAttribute('data-select2-id'))
}

// nested-form-type: suggest-select (typeaheadを使用したsuggest補完)
export function overwriteCloneSuggestSelectElement(nameInput, codeInput, suggestDataNames) {
  nameInput.id = ''
  nameInput.tabindex = ''
  nameInput.disabled = false
  nameInput.value = ''
  // サジェストの補完結果をdata属性に保存させているため、コピー時に削除しておく
  suggestDataNames.forEach((dataName) => nameInput.setAttribute(`data-${dataName}`, ''))
  codeInput.value = ''

  // jQueryに保存されている値を削除する
  const $nameInput = $(nameInput)
  suggestDataNames.forEach((dataName) => $nameInput.data(dataName, ''))
  $nameInput.typeahead('val', '')
}
