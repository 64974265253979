import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static values = {
    urlDefault: String,
    urlPhoneNumberConfirm: String,
  }

  static targets = [
    'form',
    'method',
    'medicalLicense',
    'portrait',
    'hpkiCard',
    'image',
    'imageCache',
    'phoneNumberArea',
    'phoneNumber',
    'medicalRegistryNumber',
    'nationalCertificationDate',
    'submit',
    'submitDefault',
    'submitPhoneNumberConfirm',
    'proposalForSms',
    'proposalForGeneral',
    'smsNavigator',
    'confirmMethod',
    'certificationDate',
    'confirmMethodSelect',
    'medicalStaffId',
    'medicalIdCard',
    'specialistCertificate',
    'confirmArea',
  ]
  // 入力項目
  requiredFields = [
    this.methodTarget,
    this.medicalRegistryNumberTarget,
    this.phoneNumberTarget,
    ...this.nationalCertificationDateTargets,
  ]

  connect() {
    this.changeMethod()
  }

  /**
   * 本人確認方法が変更されたとき
   */
  changeMethod() {
    this.toggleLicenses()
    this.togglePhoneNumber()
    this.toggleSubmit()
    this.toggleProposals()
    this.toggleSmsComponents()
    this.toggleHPKIComponents()
    this.changeConfirmMethod()
  }

  /**
   * 医師免許情報の表示/非表示
   */
  toggleLicenses() {
    let medicalLicenseHidden = true
    let portraitHidden = true
    let hpkiCardHidden = true
    let confirmAreaHidden = true

    switch (this.methodTarget.value) {
      case 'medical_license_only':
        medicalLicenseHidden = false
        confirmAreaHidden = false
        break
      case 'medical_license_with_portrait':
        medicalLicenseHidden = false
        portraitHidden = false
        confirmAreaHidden = false
        break
      case 'hpki_card':
        medicalLicenseHidden = true
        hpkiCardHidden = false
        break
    }

    this.medicalLicenseTarget.hidden = medicalLicenseHidden
    this.portraitTarget.hidden = portraitHidden
    this.hpkiCardTarget.hidden = hpkiCardHidden
    this.confirmAreaTarget.hidden = confirmAreaHidden
    // HACK: hiddenで取得できなかったのでdisabled属性を設定しておく
    $(this.medicalLicenseTarget)
      .find('input')
      .each((_, elm) => {
        elm.disabled = medicalLicenseHidden
      })
    $(this.portraitTarget)
      .find('input')
      .each((_, elm) => {
        elm.disabled = portraitHidden
      })
    $(this.hpkiCardTarget)
      .find('input')
      .each((_, elm) => {
        elm.disabled = hpkiCardHidden
      })
    $(this.confirmAreaTarget)
      .find('select')
      .each((_, elm) => {
        elm.disabled = confirmAreaHidden
      })
  }

  /**
   * 電話番号入力欄の表示/非表示
   */
  togglePhoneNumber() {
    const hidden = this.methodTarget.value != 'medical_license_only'
    this.phoneNumberAreaTarget.hidden = hidden
    this.phoneNumberTarget.disabled = hidden
  }

  /**
   * 送信ボタンの活性/非活性
   */
  toggleSubmit() {
    // 画像はファイル選択かキャッシュが設定されていたらOK
    let is_filled_images = this.imageTargets.every((imageTarget) => {
      return (
        $(imageTarget).is(':disabled') ||
        imageTarget.value ||
        this.imageCacheTargets.find((e) => $(e).data('index') == $(imageTarget).data('index'))?.value
      )
    })

    // 必須入力項目がdisalbedまたは入力されている場合活性化
    const disabled = !(
      is_filled_images &&
      this.requiredFields.every((input) => {
        return input.disabled || input.value
      })
    )
    this.submitTargets.forEach((element) => (element.disabled = disabled))
  }

  /**
   * ページ最下部の注意文言活性/非活性
   */
  toggleProposals() {
    if (this.methodTarget.value == 'medical_license_only') {
      this.proposalForSmsTarget.hidden = false
      this.proposalForGeneralTarget.hidden = true
    } else {
      this.proposalForSmsTarget.hidden = true
      this.proposalForGeneralTarget.hidden = false
    }
  }

  /**
   * ご本人確認方法にて「電話確認（SMS認証）」を選択した場合の、ナビゲーター、POST先URL、submitボタン文言変更
   */
  toggleSmsComponents() {
    if (this.methodTarget.value == 'medical_license_only') {
      // ナビゲーター
      this.smsNavigatorTarget.hidden = false
      // POST先URL
      this.formTarget.action = this.urlPhoneNumberConfirmValue
      // submitボタン出し分け
      this.submitPhoneNumberConfirmTarget.hidden = false
      this.submitDefaultTarget.hidden = true
    } else {
      // ナビゲーター
      this.smsNavigatorTarget.hidden = true
      // POST先URL
      this.formTarget.action = this.urlDefaultValue
      // submitボタン出し分け
      this.submitPhoneNumberConfirmTarget.hidden = true
      this.submitDefaultTarget.hidden = false
    }
  }

  /**
   * ご本人確認方法にて「HPKIカード」を選択した場合の医師・歯科医師資格確認方法、医師登録番号、登録年月日表示制御
   */
  toggleHPKIComponents() {
    if (this.methodTarget.value == 'hpki_card') {
      this.confirmMethodTarget.hidden = true
      this.medicalLicenseTarget.hidden = true
      this.certificationDateTarget.hidden = true
    } else {
      this.confirmMethodTarget.hidden = false
      this.medicalLicenseTarget.hidden = false
      this.certificationDateTarget.hidden = false
    }
  }

  /**
   * 医師・歯科医師資格確認方法が変更されたとき
   */
  changeConfirmMethod() {
    let medicalLicenseHidden = true
    let medicalStaffIdHidden = true
    let medicalIdCardHidden = true
    let specialistCertificateHidden = true
    let nationalCertificationDateHidden = true

    if (this.methodTarget.value !== 'hpki_card') {
      // ご本人確認方法 HPKIカード以外を選択時は年月日セレクトボックスを表示する
      nationalCertificationDateHidden = false

      switch (this.confirmMethodSelectTarget.value) {
        case 'medicalLicense':
          medicalLicenseHidden = false
          break
        case 'medicalStaffId':
          medicalStaffIdHidden = false
          nationalCertificationDateHidden = true
          break
        case 'medicalIdCard':
          medicalIdCardHidden = false
          break
        case 'specialistCertificate':
          specialistCertificateHidden = false
          nationalCertificationDateHidden = true
          break
      }
    }
    this.medicalLicenseTarget.hidden = medicalLicenseHidden
    this.medicalStaffIdTarget.hidden = medicalStaffIdHidden
    this.medicalIdCardTarget.hidden = medicalIdCardHidden
    this.specialistCertificateTarget.hidden = specialistCertificateHidden
    this.certificationDateTarget.hidden = nationalCertificationDateHidden

    // HACK: hiddenで取得できなかったのでdisabled属性を設定しておく
    $(this.medicalLicenseTarget)
      .find('input')
      .each((_, elm) => {
        elm.disabled = medicalLicenseHidden
      })
    $(this.medicalStaffIdTarget)
      .find('input')
      .each((_, elm) => {
        elm.disabled = medicalStaffIdHidden
      })
    $(this.medicalIdCardTarget)
      .find('input')
      .each((_, elm) => {
        elm.disabled = medicalIdCardHidden
      })
    $(this.specialistCertificateTarget)
      .find('input')
      .each((_, elm) => {
        elm.disabled = specialistCertificateHidden
      })
    $(this.certificationDateTarget)
      .find('select')
      .each((_, elm) => {
        elm.disabled = nationalCertificationDateHidden
      })
  }
}
