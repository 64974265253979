import { Controller } from 'stimulus'
import $ from 'jquery'

// typeahead
import Bloodhound from '../../../typeahead/bloodhound'

export default class extends Controller {
  static targets = ['disease_code', 'disease_name']

  initialize(_event) {
    // サジェスト要素
    this.diseaseCode = $(this.disease_codeTarget)
    this.diseaseName = $(this.disease_nameTarget)

    // データ設定
    const bloodhound = new Bloodhound({
      // この辺りはローカルデータを設定・分割するためなので適当でOKです
      // 設定しないとエラーが出るので適当に設定しておく必要があります
      // => jquery3.source.js:321 Uncaught Error: datumTokenizer is required
      local: [],
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      // リモート設定 (ajax設定)
      remote: {
        url: '/private/api/internal/v1/doctor_profile/suggest/senbei_diseases',
        prepare: (query, settings) => {
          settings.type = 'GET'
          settings.contentType = 'application/json'
          settings.data = {
            name: query,
          }
          return settings
        },
        // 検索し出すまでの待機時間 (ms)
        rateLimitWait: 200,
      },
    })

    // サジェスト設定
    this.diseaseName
      .typeahead(
        {
          // n文字以上になったらサジェストを行う
          minLength: 1,
          // 一致する語句をハイライト(太字)にする
          highlight: true,
          // ヒントを非表示
          hint: false,
        },
        {
          // レスポンスの`name`キーのリストをサジェストリストとして利用する
          displayKey: 'name',
          limit: 1000,
          source: bloodhound,
          templates: {
            header: '<div class="tt-header">疾患名</div>',
            empty: '<div class="tt-suggestion tt-selectable">No Results</div>',
          },
        }
      )
      .on('typeahead:selected typeahead:autocomplete', (_e, disease) => {
        // // 選択肢が選ばれたときは、dataに情報を埋め込む
        this.diseaseName.attr('data-doctor-profiles--shared--suggest-diseases-disease-code', disease.code)
        this.diseaseName.data('doctor-profiles--shared--suggest-diseases-disease-code', disease.code)

        this.diseaseName.attr('data-doctor-profiles--shared--suggest-diseases-disease-name', disease.name)
        this.diseaseName.data('doctor-profiles--shared--suggest-diseases-disease-name', disease.name)
        this.onDiseaseNameChanged()
      })
      .on('change', (_e) => {
        this.onDiseaseNameChanged()
      })
      .on('blur', (_e) => {
        // フォーカスアウト時、入力途中の内容を削除する
        const { diseaseName } = this.loadSuggestResult()
        if (this.diseaseName.val() != diseaseName) {
          this.diseaseName.typeahead('val', '')
        }
      })
  }

  onDiseaseNameChanged() {
    // dataからサジェスト結果を読み込む
    const { diseaseCode, diseaseName } = this.loadSuggestResult()

    // サジェスト結果と入力が一致していたら、hiddenフォームのdisease_codeを設定する
    if (diseaseName === this.diseaseName.val()) {
      this.diseaseCode.val(diseaseCode)
      return
    }

    // サジェスト適用後、入力が変更されたら、サジェスト結果とhiddenフォームのdisease_codeをクリアする
    if (this.diseaseCode.val() && diseaseName !== this.diseaseName.val()) {
      this.clearSuggestResult()
      this.diseaseCode.val('')
    }
  }

  // dataにサジェスト結果を埋め込む
  saveSuggestResult(disease) {
    this.diseaseName.attr('data-doctor-profiles--shared--suggest-diseases-disease-code', disease.code)
    this.diseaseName.data('doctor-profiles--shared--suggest-diseases-disease-code', disease.code)

    this.diseaseName.attr('data-doctor-profiles--shared--suggest-diseases-disease-name', disease.name)
    this.diseaseName.data('doctor-profiles--shared--suggest-diseases-disease-name', disease.name)
  }

  // dataからサジェスト結果を読み込む
  loadSuggestResult() {
    return {
      diseaseCode: this.diseaseName.data('doctor-profiles--shared--suggest-diseases-disease-code'),
      diseaseName: this.diseaseName.data('doctor-profiles--shared--suggest-diseases-disease-name'),
    }
  }

  // dataに保存したサジェスト結果を削除する
  clearSuggestResult() {
    this.diseaseName.attr('data-doctor-profiles--shared--suggest-diseases-disease-code', '')
    this.diseaseName.data('doctor-profiles--shared--suggest-diseases-disease-code', '')

    this.diseaseName.attr('data-doctor-profiles--shared--suggest-diseases-disease-name', '')
    this.diseaseName.data('doctor-profiles--shared--suggest-diseases-disease-name', '')
  }
}
