import { Controller } from 'stimulus'
import $ from 'jquery'

// typeahead
import Bloodhound from '../../typeahead/bloodhound'

/**
 * 医師情報編集のstep2コントローラ
 * FYI: app/views/doctor_details/step2.html.slim
 */
export default class extends Controller {
  static targets = ['universityInput']

  connect() {
    this.activationToken = $(this.element).data('token')
    this.initializeUniversity()
    this.invalidationEnter()
  }

  /**
   * 大学クエリ用のBloodhoundを作成
   */
  initializeUniversity() {
    // データ設定
    const bloodhound = new Bloodhound({
      // この辺りはローカルデータを設定・分割するためなので適当でOKです
      // 設定しないとエラーが出るので適当に設定しておく必要があります
      // => jquery3.source.js:321 Uncaught Error: datumTokenizer is required
      local: [],
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      // リモート設定 (ajax設定)
      remote: {
        url: '/private/api/v1/universities/search',
        prepare: (query, settings) => {
          settings.type = 'GET'
          settings.contentType = 'application/json'
          settings.data = {
            token: this.activationToken,
            name: query,
          }
          return settings
        },
        // 検索し出すまでの待機時間 (ms)
        rateLimitWait: 200,
      },
    })

    // サジェスト設定
    this.universityInputTargets.forEach((element) => {
      $(element).typeahead(
        {
          // n文字以上になったらサジェストを行う
          minLength: 1,
          // 一致する語句をハイライト(太字)にする
          highlight: true,
          // ヒントを非表示
          hint: false,
        },
        {
          // レスポンスの`name`キーのリストをサジェストリストとして利用する
          displayKey: 'name',
          limit: 100,
          source: bloodhound,
          templates: {
            header: '<div class="tt-header">大学名候補</div>',
          },
        }
      )
    })
  }

  invalidationEnter() {
    // Enterキーを無効化
    $('input').keydown(function (e) {
      if ((e.which && e.which === 13) || (e.keyCode && e.keyCode === 13)) {
        return false
      } else {
        return true
      }
    })
  }
}
