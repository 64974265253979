import { Controller } from 'stimulus'
import $ from 'jquery'

import { overwriteCloneSuggestSelectElement } from 'controllers/doctor_profiles/utils/nested_form'

// typeahead
import Bloodhound from '../../../typeahead/bloodhound'

export default class extends Controller {
  static targets = ['prefecture_id', 'institution_code', 'institution_name', 'institution_fieldset']

  connect() {
    // サジェスト要素
    this.institutionFieldset = $(this.institution_fieldsetTarget)
    this.institutionCode = $(this.institution_codeTarget)
    this.institutionName = $(this.institution_nameTarget)
    // 勤務先エリア
    this.prefectureId = $(this.prefecture_idTarget)

    const optionNone = $(this.element).attr(
      'data-doctor-profiles--shared--suggest-institutions-by-prefecture-option-none'
    )
    const optionAddress = $(this.element).attr(
      'data-doctor-profiles--shared--suggest-institutions-by-prefecture-option-address'
    )

    // サジェスト初期化
    this.initializeBloodhound(optionAddress, optionNone)

    // サジェスト操作
    this.institutionName
      .on('typeahead:selected typeahead:autocomplete', (_e, institution) => {
        // 選択肢が選ばれたときは、dataに情報を埋め込む
        this.saveSuggestResult(institution)
        // Mac safariは、trigger('change') によるイベントでdata-action属性をフックできないため
        // change event を作成して dispatch する
        this.institutionName.get(0).dispatchEvent(new Event('change'))
      })
      .on('change', (_e) => {
        this.onInstitutionNameChanged()
      })
      .on('blur', (_e) => {
        // フォーカスアウト時、入力途中の内容を削除する
        const { institutionName } = this.loadSuggestResult()
        if (this.institutionName.val() != institutionName) {
          this.institutionName.typeahead('val', '')
        }
      })
  }

  onInstitutionNameChanged() {
    // dataからサジェスト結果を読み込む
    const { institutionCode, institutionName } = this.loadSuggestResult()

    // サジェスト結果と入力が一致していたら、hiddenフォームのinstitution_codeを設定する
    if (institutionName === this.institutionName.val()) {
      this.institutionCode.val(institutionCode)

      if (institutionCode == -1) {
        this.institutionFieldset.prop('disabled', true)
      }

      return
    }

    // サジェスト適用後、入力が変更されたら、サジェスト結果とhiddenフォームのinstitution_codeをクリアする
    if (this.institutionCode.val() && institutionName !== this.institutionName.val()) {
      this.clearSuggestResult()
      this.institutionCode.val('')
    }
  }

  resetInstitutionSuggest(_event) {
    overwriteCloneSuggestSelectElement(this.institutionName.get(0), this.institutionCode.get(0), [
      'doctor-profiles--shared--suggest-institutions-by-prefeture-institution-code',
      'doctor-profiles--shared--suggest-institutions-by-prefeture-institution-name',
    ])
    this.institutionFieldset.prop('disabled', this.prefectureId.val() == '')
  }

  // dataにサジェスト結果を埋め込む
  saveSuggestResult(institution) {
    this.institutionName.attr(
      'data-doctor-profiles--shared--suggest-institutions-by-prefeture-institution-code',
      institution.code
    )
    this.institutionName.data(
      'doctor-profiles--shared--suggest-institutions-by-prefeture-institution-code',
      institution.code
    )

    this.institutionName.attr(
      'data-doctor-profiles--shared--suggest-institutions-by-prefeture-institution-name',
      institution.name
    )
    this.institutionName.data(
      'doctor-profiles--shared--suggest-institutions-by-prefeture-institution-name',
      institution.name
    )
  }

  // dataからサジェスト結果を読み込む
  loadSuggestResult() {
    return {
      institutionCode: this.institutionName.data(
        'doctor-profiles--shared--suggest-institutions-by-prefeture-institution-code'
      ),
      institutionName: this.institutionName.data(
        'doctor-profiles--shared--suggest-institutions-by-prefeture-institution-name'
      ),
    }
  }

  // dataに保存したサジェスト結果を削除する
  clearSuggestResult() {
    this.institutionName.attr('data-doctor-profiles--shared--suggest-institutions-by-prefeture-institution-code', '')
    this.institutionName.data('doctor-profiles--shared--suggest-institutions-by-prefeture-institution-code', '')

    this.institutionName.attr('data-doctor-profiles--shared--suggest-institutions-by-prefeture-institution-name', '')
    this.institutionName.data('doctor-profiles--shared--suggest-institutions-by-prefeture-institution-name', '')
  }

  initializeBloodhound(optionAddress, optionNone) {
    // データ設定
    const bloodhound = new Bloodhound({
      // この辺りはローカルデータを設定・分割するためなので適当でOKです
      // 設定しないとエラーが出るので適当に設定しておく必要があります
      // => jquery3.source.js:321 Uncaught Error: datumTokenizer is required
      local: [],
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      // リモート設定 (ajax設定)
      remote: {
        url: '/private/api/v1/institutions/search',
        prepare: (query, settings) => {
          settings.type = 'GET'
          settings.contentType = 'application/json'
          settings.data = {
            name: query,
            prefecture_id: this.prefectureId.val(),
          }
          return settings
        },
        transform: (response) => {
          if (optionAddress === 'true') {
            response.forEach((option) => {
              option.name += option.address ? '（' + option.address + ')' : ''
            })
          }

          // APIから返される「{code: '', name: '選択候補に出ない'}」を削除
          response = response.filter((option) => option.name !== '選択候補に出ない')
          if (optionNone === 'true') {
            response.push({ code: -1, name: '選択候補に出ない' })
          }
          return response
        },
        // 検索し出すまでの待機時間 (ms)
        rateLimitWait: 200,
      },
    })

    // サジェスト設定
    this.institutionName.typeahead(
      {
        // n文字以上になったらサジェストを行う
        minLength: 1,
        // 一致する語句をハイライト(太字)にする
        highlight: true,
        // ヒントを非表示
        hint: false,
      },
      {
        // レスポンスの`name`キーのリストをサジェストリストとして利用する
        displayKey: 'name',
        limit: 1000,
        source: bloodhound,
        templates: {
          header: '<div class="tt-header">勤務先名</div>',
        },
      }
    )
  }
}
