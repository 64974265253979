import { Controller } from 'stimulus'
import $ from 'jquery'

/**
 * 検索ページファセット用コントローラー
 *
 * app/views/search/partial/_result_header.html.slim で search_facet_controller を呼び出すと
 * app/views/search/partial/facets/_period.html.slim の target が null になってエラーとなるため
 * search_facet_controller.js から分離。
 */
export default class extends Controller {
  initialize() {
    this.currentWitdh = window.innerWidth
  }

  clickOnSpecifyingConditions() {
    // SP 検索ファセットエリアの表示制御
    const navFacet = $('.js-search-facet')
    const facetArea = $('.js-faset-area')
    if (navFacet.hasClass('is-active')) {
      navFacet.removeClass('is-active')
      facetArea.hide()
    } else {
      navFacet.addClass('is-active')
      facetArea.show()
    }
  }

  resizeWindow() {
    // SP 検索ファセットエリアの表示制御
    const navFacet = $('.js-search-facet')
    const facetArea = $('.js-faset-area')
    const widthThreshold = 1000
    // HACK. iOS Safari は iOS 8 からスクロール時は常にリサイズイベントを発火するようになった。
    // ウィンドの横幅が変わらない場合、なにもしない。
    if (this.currentWidth == window.innerWidth) {
      return
    }
    this.currentWidth = window.innerWidth

    // 表示を初期化する。
    navFacet.removeClass('is-active')
    facetArea.hide()

    if (this.currentWidth >= widthThreshold) {
      facetArea.show()
    }
  }
}
