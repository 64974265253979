import { Controller } from 'stimulus'
import { multiPart } from 'controllers/doctor_profiles/mixins/multi_part'

import { overwriteCloneSuggestSelectElement } from 'controllers/doctor_profiles/utils/nested_form'

export default class extends Controller {
  static targets = ['append_elem', 'add_elem', 'destroy_button']

  connect() {
    if (this.add_elemTargets.length > 1) {
      this.destroy_buttonTargets.forEach((element) => {
        element.style.display = 'flex'
      })
    }

    multiPart(this)
    this.initializeView('data-doctor-profiles--workplaces-target', false, 'block')
  }

  addAssociation(event) {
    event.preventDefault()

    const clonedAddItem = this.add_elemTarget.cloneNode(true)

    this.resetAllInputValue(clonedAddItem)
    this.resetValidError(clonedAddItem)

    clonedAddItem.querySelector('[data-doctor-profiles--workplaces-area="input_detail_area"]').style.display = 'none'

    this.append_elemTarget.appendChild(clonedAddItem)

    if (this.add_elemTargets.length > 1) {
      this.destroy_buttonTargets.forEach((element) => {
        element.style.display = 'flex'
      })
    }
    this.settingDefaultValue(clonedAddItem)
  }

  buildInstitutionDetail(event) {
    const targetElement = event.target.closest('[data-doctor-profiles--workplaces-target="add_elem"]')

    this.resetAllInputValue(targetElement, false)
    this.resetValidError(targetElement)

    const manualInputElement = targetElement.querySelector('[data-doctor-profiles--workplaces-area="manual_input"]')
    const inputDetailArea = targetElement.querySelector('[data-doctor-profiles--workplaces-area="input_detail_area"]')
    const inputDomesticArea = targetElement.querySelector(
      '[data-doctor-profiles--workplaces-area="input_domestic_area"]'
    )
    const institutionAddressLabelRequire = targetElement.querySelector(
      '[data-doctor-profiles--workplaces-area="institution_address_label_require"]'
    )
    const institutionAddressLabelAny = targetElement.querySelector(
      '[data-doctor-profiles--workplaces-area="institution_address_label_any"]'
    )

    inputDetailArea.style.display = 'block'

    if (event.target.value == 'domestic_hospital_or_clinic') {
      manualInputElement.style.display = 'none'
      inputDomesticArea.style.display = 'block'
      institutionAddressLabelRequire.style.display = 'block'
      institutionAddressLabelAny.style.display = 'none'
    } else if (event.target.value == 'foreign_hospital_or_company') {
      manualInputElement.style.display = 'block'
      inputDomesticArea.style.display = 'none'
      institutionAddressLabelRequire.style.display = 'none'
      institutionAddressLabelAny.style.display = 'block'
    } else {
      targetElement.querySelector('[data-doctor-profiles--workplaces-area="input_detail_area"]').style.display = 'none'
    }
  }

  hiddenManualInputArea(event) {
    const targetElement = event.target.closest('[data-doctor-profiles--workplaces-area="input_detail_area"]')
    const addElement = event.target.closest('[data-doctor-profiles--workplaces-target="add_elem"]')

    this.resetAllInputValue(addElement, false, false, false)
    this.resetValidError(addElement)

    targetElement.querySelector('[data-doctor-profiles--workplaces-area="manual_input"]').style.display = 'none'
  }

  showManualInputAreaIfNeeded(event) {
    const targetValue = event.target.getAttribute(
      'data-doctor-profiles--shared--suggest-institutions-by-prefeture-institution-code'
    )
    if (targetValue == -1) {
      const targetElement = event.target.closest('[data-doctor-profiles--workplaces-area="input_detail_area"]')
      const addElement = event.target.closest('[data-doctor-profiles--workplaces-target="add_elem"]')

      this.resetAllInputValue(addElement, false, false, false)
      this.resetValidError(addElement)

      targetElement.querySelector('[data-doctor-profiles--workplaces-area="manual_input"]').style.display = 'block'
    }
  }

  unDisabled(event) {
    // typeahead フォームを活性化
    const institutionSuggestFieldsets = document.querySelectorAll(
      '[data-doctor-profiles--shared--suggest-institutions-by-prefecture-target="institution_fieldset"]'
    )
    institutionSuggestFieldsets.forEach((element) => (element.disabled = false))

    event.target.disabled = true
    event.target.form.submit()
  }

  resetValidError(targetElement) {
    Array.from(targetElement.getElementsByClassName('p-doctor-profile-edit__box__form__part')).forEach((element) => {
      if (element.getElementsByClassName('c-form-input--error').length) {
        element.getElementsByClassName('c-form-input--error')[0].classList.remove('c-form-input--error')
      }

      if (element.getElementsByClassName('field_with_errors').length) {
        element.getElementsByClassName('field_with_errors')[0].classList.remove('field_with_errors')
      }

      if (element.getElementsByClassName('c-form-input--error__message').length) {
        element.getElementsByClassName('c-form-input--error__message')[0].remove()
      }
    })
  }

  resetAllInputValue(
    targetElement,
    isResetWorkplaceKind = true,
    isResetPrefectureId = true,
    isResetInstitution = true
  ) {
    targetElement.querySelector('[data-doctor-profiles--workplaces-form="institution_name"]').value = ''
    targetElement.querySelector('[data-doctor-profiles--workplaces-form="institution_address"]').value = ''
    targetElement.querySelector('[data-doctor-profiles--workplaces-form="affiliation"]').value = ''
    targetElement.querySelector('[data-doctor-profiles--workplaces-form="position"]').value = ''

    if (isResetWorkplaceKind)
      targetElement.querySelector('[data-doctor-profiles--workplaces-form="workplace_kind"]').options[0].selected = true
    if (isResetPrefectureId)
      targetElement.querySelector('[data-doctor-profiles--workplaces-form="prefecture_id"]').value = ''
    if (isResetInstitution) {
      const institutionSuggestFieldset = targetElement.querySelector(
        '[data-doctor-profiles--shared--suggest-institutions-by-prefecture-target="institution_fieldset"]'
      )
      const institutionSuggestName = targetElement.querySelector(
        '[data-doctor-profiles--shared--suggest-institutions-by-prefecture-target="institution_name"]'
      )
      const institutionSuggestCode = targetElement.querySelector(
        '[data-doctor-profiles--shared--suggest-institutions-by-prefecture-target="institution_code"]'
      )

      overwriteCloneSuggestSelectElement(institutionSuggestName, institutionSuggestCode, [
        'doctor-profiles--shared--suggest-institutions-by-prefeture-institution-code',
        'doctor-profiles--shared--suggest-institutions-by-prefeture-institution-name',
      ])
      institutionSuggestFieldset.disabled = true
    }
  }
}
