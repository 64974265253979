import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['file_upload', 'toggle_accordion']

  changeUploadImage() {
    const uploader = document.querySelector('.p-doctor-profile-edit__box__form__part-select')
    if (uploader.value.length) {
      // 画画像選択されたとき
      const file = uploader.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const image = reader.result
        document.querySelector('.p-doctor-profile-edit__box__wrap__left-image__display').setAttribute('src', image)
      }
    } else {
      // 未選択のとき
      const image = uploader.getAttribute('data-doctor-profiles--profile-images-no-src')
      document.querySelector('.p-doctor-profile-edit__box__wrap__left-image__display').setAttribute('src', image)
    }
  }

  // TODO: メソッドを分けたかったが、同じコントローラ内のメソッドを呼び出すと undefined となる。
  // おそらく対処法はあるはずなのでリファクタする。
  toggleAccordion() {
    const el = document.querySelector('.p-doctor-profile-edit__box__accordion')
    const iconEl = document.querySelector('.p-doctor-profile-edit__box-text-confirm-icon')
    const duration = 500
    if (window.getComputedStyle(el).display === 'none') {
      el.style.removeProperty('display')
      let display = window.getComputedStyle(el).display
      if (display === 'none') {
        display = 'block'
      }
      iconEl.style.transform = 'rotate(' + 180 + 'deg)'
      el.style.display = display
      let height = el.offsetHeight
      el.style.overflow = 'hidden'
      el.style.height = 0
      el.style.paddingTop = 0
      el.style.paddingBottom = 0
      el.style.marginTop = 0
      el.style.marginBottom = 0
      el.offsetHeight
      el.style.transitionProperty = 'height, margin, padding'
      el.style.transitionDuration = duration + 'ms'
      el.style.transitionTimingFunction = 'ease'
      el.style.height = height + 'px'
      el.style.removeProperty('padding-top')
      el.style.removeProperty('padding-bottom')
      el.style.removeProperty('margin-top')
      el.style.removeProperty('margin-bottom')
      setTimeout(() => {
        el.style.removeProperty('height')
        el.style.removeProperty('overflow')
        el.style.removeProperty('transition-duration')
        el.style.removeProperty('transition-property')
        el.style.removeProperty('transition-timing-function')
      }, duration)
    } else {
      iconEl.style.transform = 'rotate(' + 360 + 'deg)'
      el.style.height = el.offsetHeight + 'px'
      el.offsetHeight
      el.style.transitionProperty = 'height, margin, padding'
      el.style.transitionDuration = duration + 'ms'
      el.style.transitionTimingFunction = 'ease'
      el.style.overflow = 'hidden'
      el.style.height = 0
      el.style.paddingTop = 0
      el.style.paddingBottom = 0
      el.style.marginTop = 0
      el.style.marginBottom = 0
      setTimeout(() => {
        el.style.display = 'none'
        el.style.removeProperty('height')
        el.style.removeProperty('padding-top')
        el.style.removeProperty('padding-bottom')
        el.style.removeProperty('margin-top')
        el.style.removeProperty('margin-bottom')
        el.style.removeProperty('overflow')
        el.style.removeProperty('transition-duration')
        el.style.removeProperty('transition-property')
        el.style.removeProperty('transition-timing-function')
      }, duration)
    }
  }
}
