import { Controller } from 'stimulus'
import $ from 'jquery'

/**
 * 一括仮アカウント発行csvアップロードのコントローラ
 * FYI: app/views/admin/users/doctors/bulk_create_index.slim
 */
export default class extends Controller {
  static targets = ['csvForm', 'csvUploadError', 'csvFileField']

  connect() {
    this.csvUploadError = $(this.csvUploadErrorTarget)
  }

  FileValidateAndUpload(event) {
    // スピナー
    $(document).ajaxSend(function () {
      $('.overlay').fadeIn(300)
    })
    // admin/users/doctors/csv_upload にPOST
    const formattedCsv = new FormData(this.csvFormTarget)
    const self = this
    const header = { 'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content }
    $.ajax({
      data: formattedCsv,
      type: 'POST',
      url: '/admin/users/doctors/csv_upload',
      cache: false,
      contentType: false,
      processData: false,
      headers: header,
      success: function (data) {
        self.handleResponse(data)
      },
      error: function (_, status) {
        if (status == 'timeout') {
          self.appendError([
            'タイムアウトが発生しました。時間を置いて再度同じCSVをアップロードしてください。何度も発生する場合は管理者にお問い合わせください。',
          ])
        }
      },
      complete: function () {
        // 同じファイル名を選択するとイベントが発火しないので空にする
        event.target.value = null
      },
      timeout: 10 * 60 * 1000, // timeoutを10分に設定
    }).always(function () {
      setTimeout(function () {
        $('.overlay').fadeOut(300)
      }, 500)
    })
  }

  appendError(messages) {
    this.csvUploadError.empty()
    messages.forEach((message) => this.csvUploadError.append(`<li>${message}</li>`))
    this.csvUploadError.show()
  }

  handleResponse(data) {
    if (data.success) {
      window.location.href = '/admin/users/doctors/bulk_create_index' + '?success=true'
    } else {
      $(this.csvFileFieldTarget).val('')
      if (data.error) {
        this.appendError(data.error.messages)
      } else {
        const bom = '\uFEFF'
        let blob
        // bom付きで受けっとったらそのまま、bomが付いていなければ付けて返す
        if (data.indexOf(bom)) {
          blob = new Blob([bom, data])
        } else {
          blob = new Blob([data])
        }
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute('download', 'error.csv')
        document.body.appendChild(link)
        link.click()
        this.appendError(['エラーが発生しました。ダウンロードされたerror.csvをご確認ください'])
      }
    }
  }
}
