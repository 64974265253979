import { Controller } from 'stimulus'
import $ from 'jquery'

/**
 * ブラウザバックを利用して遷移してきたときにリロードし直すコントローラ
 * (DOM操作を行ったフォームのリフレッシュなどに使用)
 *
 * - ページのどこかに1つだけ設定すればOKです!
 * e.g.
 *   body
 *     main[data-controller='back-forward-reload']
 *       .container
 *         .row
 *           .col
 *             h1 foo
 *             p bar
 */
export default class extends Controller {
  connect() {
    // ブラウザバックで戻ってきた時にリロードして初期化する
    $(window).on('pageshow', (event) => {
      // HACK: スクロール位置を0にセット (IE11対応)
      if (this.isInternetExplorer()) {
        $('html,body').animate({ scrollTop: 0 }, '1')
      }

      // Safari, Firefox (キャッシュが使用されているかで判別できる)
      // FYI: https://developer.mozilla.org/en-US/docs/Web/API/PageTransitionEvent/persisted
      if (event.persisted) {
        this.reload()
        return
      }

      // Chrome, Safari, Firefox, Edge
      // FYI: https://developer.mozilla.org/ja/docs/Web/API/PerformanceNavigationTiming
      if (performance.getEntriesByType('navigation').some((e) => e.type === 'back_forward')) {
        this.reload()
        return
      }

      // IE (deprecated)
      // FYI: https://developer.mozilla.org/ja/docs/Web/API/PerformanceNavigation/type
      if (performance.navigation.type === performance.navigation.TYPE_BACK_FORWARD) {
        this.reload()
      }
    })
  }

  reload() {
    // `window.location.reload`後にスクロール位置が戻らないのでリロード前にトップに戻しておく
    $(window).on('beforeunload', () => {
      $('body').hide()
      $(window).scrollTop(0)
    })
    // すぐにリロードすると上記のコールバックが反映されなかったので遅延実行
    // (IE11以外はこれで大丈夫だった)
    setTimeout(() => {
      // ページリロード
      window.location.reload()
    }, 1)
  }

  isInternetExplorer() {
    const userAgent = window.navigator.userAgent.toLowerCase()
    return userAgent.indexOf('msie') !== -1 || userAgent.indexOf('trident') !== -1
  }
}
