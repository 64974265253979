import { Controller } from 'stimulus'

/**
 * 製薬リクエストのボタン表示
 * FYI: app/views/shared/_pharma_inquiry_button.html.slim
 */
export default class extends Controller {
  initialize() {
    this.controllerName = 'pharma-inquiry-button'
    this.resizeEvent = this.onResize.bind(this)
    this.scrollEvent = this.onScroll.bind(this)
  }

  connect() {
    // WARNING: 同じコントローラを使用している画面に遷移したときの`this.element`がおかしいのでjQueryで取得し直している
    //          (多分、turbolinks+stimulusのbug)
    this.target = $(`[data-controller="${this.controllerName}"]`)
    this.window = $(window)
    this.activeClass = 'is-active'
    this.threshold = 200
    this.resizeTimeoutId = null
    this.resizeTimeout = 200
    this.scrollAnimationFrameId = null

    // SP時に製薬リクエストボタンが下部に表示されるので被らないようにしたいとのこと (#206)
    $('.js-footer').addClass('l-footer--inquiry')

    // イベントの登録
    // (jQueryではオプションを指定できないので`addEventListener`を使用)
    // FIXME: IEの時は`passive: true`が使用できないので`false` (IEのサポートが切れたら`{ passive: true }`でOK!)
    this.eventOptions = document.documentMode ? false : { passive: true }
    window.addEventListener('resize', this.resizeEvent, this.eventOptions)
    window.addEventListener('scroll', this.scrollEvent, this.eventOptions)

    // 初期化
    this.updateElement()
  }

  disconnect() {
    // setTimeoutのクリア
    if (this.resizeTimeoutId) {
      clearTimeout(this.resizeTimeoutId)
    }
    // requestAnimationFrameのクリア
    if (this.scrollAnimationFrameId) {
      window.cancelAnimationFrame(this.scrollAnimationFrameId)
      this.scrollAnimationFrameId = null
    }
    // addEventListenerのクリア
    window.removeEventListener('resize', this.resizeEvent, this.eventOptions)
    window.removeEventListener('scroll', this.scrollEvent, this.eventOptions)
  }

  /**
   * 表示の切り替え
   */
  updateElement() {
    // WARNING: `window.scrollY`はIEで使えなかったので注意
    const scrollY = this.window.scrollTop()
    // ドキュメントのサイズがviewportより小さくて閾値以上のスクロールができない場合 または
    // 閾値以上のスクロールした場合
    if (document.body.clientHeight < window.innerHeight + this.threshold || this.threshold <= scrollY) {
      this.target.addClass(this.activeClass)
    } else {
      this.target.removeClass(this.activeClass)
    }
  }

  /**
   * resizeのコールバック
   */
  onResize() {
    // setTimeoutのクリア
    if (this.resizeTimeoutId) {
      clearTimeout(this.resizeTimeoutId)
    }
    // 最適化のため遅延実行
    this.resizeTimeoutId = setTimeout(() => {
      this.updateElement()
      this.resizeTimeoutId = null
    }, this.resizeTimeout)
  }

  /**
   * scrollのコールバック
   */
  onScroll() {
    // リクエスト中は無視
    if (this.scrollAnimationFrameId) {
      return
    }
    // 最適化のため再描画のタイミングで更新
    this.scrollAnimationFrameId = window.requestAnimationFrame(() => {
      this.updateElement()
      this.scrollAnimationFrameId = null
    })
  }
}
