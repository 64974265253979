import { Controller } from 'stimulus'

/**
 * 検索フォームの処理
 *
 * e.g.
 *   = form_with url: url_for(action: :result), method: :get do |f|
 *     .c-search-field[data-controller='search-field']
 *       = f.search_field :q, class: 'form-control'
 *       // キャンセルボタン (optional)
 *       = link_to '', 'javascript:void(0)', class: 'c-search-field__cancel-button'
 *
 * FYI: scss側も参考にしてください
 *   `app/javascript/stylesheets/object/component/_c-search-field.scss`
 */
export default class extends Controller {
  connect() {
    this.parent = $(this.element)
    this.formControl = this.parent.find('.form-control')
    this.cancelButton = this.parent.find('.c-search-field__cancel-button')

    // 初期状態設定
    this.onFormControlChanged()

    this.formControl.keyup(() => {
      this.onFormControlChanged()
    })
    this.cancelButton.click(() => {
      this.onCancelButtonClicked()
    })
  }

  onFormControlChanged() {
    const text = this.formControl.val()
    if (text.length) {
      this.cancelButton.addClass('show')
    } else {
      this.cancelButton.removeClass('show')
    }
  }

  onCancelButtonClicked() {
    this.formControl.val('')
    this.cancelButton.removeClass('show')
  }
}
