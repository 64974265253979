import { Controller } from 'stimulus'
import $ from 'jquery'

require('select2/dist/css/select2')
require('select2-bootstrap-theme/dist/select2-bootstrap')

import 'select2'

/**
 * select2の初期化、エラーハンドリングを行うコントローラ
 */
export default class extends Controller {
  static values = {
    allowClear: Boolean,
  }

  connect() {
    this.searchSelect = $(this.element)
    this.initializeSelect2()
    this.copySelectCssClasses()
    this.displaySelect2Error()

    // select2 の change イベントは data-action 属性でフックできないため、自前でイベントを作成する
    this.searchSelect.on('change', (event) => {
      const customEvent = new CustomEvent('search-select:change', { detail: event })
      this.searchSelect.get(0).dispatchEvent(customEvent)
    })
  }

  disconnect() {
    // turbolinksのキャッシュが残りセレクトボックスが増加する場合があるので残っていた場合は削除を行う
    this.searchSelect.nextAll('.select2').remove()
  }

  initializeSelect2() {
    // turbolinksのキャッシュが残りセレクトボックスが増加する場合があるので残っていた場合は削除を行う
    this.searchSelect.nextAll('.select2').remove()
    this.searchSelect.select2({
      theme: 'bootstrap',
      // WARNING: 明示的に指定しないと適当な値が設定される
      // FYI: https://select2.org/appearance#container-width
      width: null,
      placeholder: '選択してください',
      allowClear: this.allowClearValue,
    })
  }

  copySelectCssClasses() {
    // select要素のclassをSelect2フォームにコピーする
    // Select2 によって付与されたクラスは除外する
    const cssClasses = this.searchSelect
      .attr('class')
      .split(' ')
      .filter((cssClass) => !cssClass.match(/^select2-.+/))
      .filter((cssClass) => !['c-form-input--error', 'c-form-select'].includes(cssClass))
    this.getSelect2FormWrapper().addClass(cssClasses)
    // select2のwrapperいっぱいに横幅を広げる
    this.getSelect2Form().css('width', '100%')
  }

  /**
   * Select2の要素は通常のフォーム要素とは違うため、独自で定義を行う
   * Select2を適用したselect要素にエラークラスが割り当てられていた場合、Select2のフォームをエラー表示にする
   */
  displaySelect2Error() {
    if (this.searchSelect.hasClass('c-form-input--error')) {
      this.getSelect2Form().addClass('select2-selection__error')
    }
  }

  // Select2 を適用したselect要素の直後の Select2フォームを取得する
  getSelect2Form() {
    return this.getSelect2FormWrapper().find('.select2-selection:first')
  }
  getSelect2FormWrapper() {
    return this.searchSelect.next('.select2-container:first')
  }
}
