import { Controller } from 'stimulus'
import $ from 'jquery'

require('select2/dist/css/select2')
require('select2-bootstrap-theme/dist/select2-bootstrap')

import 'select2'

/**
 * select2の初期化、エラーハンドリングを行うコントローラ
 */
export default class extends Controller {
  static values = {
    allowClear: Boolean,
  }

  connect() {
    this.initializeSelect2()
    this.displaySelect2Error()
  }

  initializeSelect2() {
    // turbolinksのキャッシュが残りセレクトボックスが増加する場合があるので残っていた場合は削除を行う
    $(this.element).nextAll('.select2').remove()
    $(this.element).select2({
      theme: 'bootstrap',
      // WARNING: 明示的に指定しないと適当な値が設定される
      // FYI: https://select2.org/appearance#container-width
      width: '100%',
      placeholder: '選択してください',
      allowClear: this.allowClearValue,
    })
  }

  /**
   * Select2の要素は通常のフォーム要素とは違うため、独自で定義を行う
   * Select2用に独自定義したエラー要素(#select2Error)が存在すれば、初めのフォームをエラー表示にする
   */
  displaySelect2Error() {
    if ($('#select2Error').length) {
      $('.select2-selection:first').addClass('select2-selection__error')
    }
  }
}
