// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Babel Polyfill (IE11対応)
import 'core-js/stable'
import 'regenerator-runtime/runtime'

// Stimulus Polyfills (IE11対応)
import '@stimulus/polyfills'

import Rails from '@rails/ujs'

// リリースバージョンのTurbolinks
// import Turbolinks from 'turbolinks'
//
// * TurbolinksはPOSTフォームのリロード処理ができるバージョンを使用
// FYI: https://github.com/turbolinks/turbolinks/pull/495
// (branch: domchristie:refresh_post)
//
// サポート:                   Chrome, Firefox
// 仕様に沿ってないが動くらしい:  Safari
// 動作不明:                   Edge, IE11
// (※ 一応、上記のブラウザでは動作することは確認済みです!)
//
// 注意事項:
//   - そもそも次期バージョン`turbo`というのがあるのでそっちにマージされるかも?
//     - https://github.com/hotwired/turbo
//
// 使用したい場合はビルド済みのものが下記にあるのでimportを下記に変更してください
import Turbolinks from '../turbolinks/turbolinks'

import * as ActiveStorage from '@rails/activestorage'
import 'channels'
import 'bootstrap'
import '../stylesheets/application'

// typeahead
import '../typeahead/typeahead.jquery'

// stimulus
import 'controllers'

require('jquery')
require('@nathanvda/cocoon')

const images = require.context('../images', true)
const imagePath = (name) => images(name, true) // eslint-disable-line no-unused-vars

Rails.start()
Turbolinks.start()
ActiveStorage.start()
