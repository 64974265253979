export const multiPart = (controller) => {
  Object.defineProperty(controller, 'addElemAttribute', {
    value: '',
    writable: true,
  })
  Object.defineProperty(controller, 'isDeleteLastElem', {
    value: false,
    writable: true,
  })
  Object.defineProperty(controller, 'addDisplayStyle', {
    value: '',
    writable: true,
  })

  Object.assign(controller, {
    initializeView(add_elem_attribute, isDeleteLastElem = false, addDisplayStyle = '') {
      this.addElemAttribute = add_elem_attribute
      this.isDeleteLastElem = isDeleteLastElem
      this.addDisplayStyle = addDisplayStyle
      this.add_elemTargets.forEach((add_elem) => {
        const destroy_element = this.getDestroyElement(add_elem)
        if (destroy_element?.value === '1') {
          add_elem.style.display = 'none'
        }
      })
      const view_elements = this.add_elemTargets.filter((element) => {
        return this.getDestroyElement(element)?.value === '0'
      })
      if (!this.isDeleteLastElem && (this.add_elemTargets.length === 1 || view_elements.length === 1)) {
        this.destroy_buttonTargets.forEach((element) => {
          element.style.display = 'none'
        })
      }
    },
    removeAssociation(event) {
      event.preventDefault()
      const add_elem = this.getAddElement(event.target)
      const id_elem = this.getIdElement(add_elem)
      const _destroy_elem = this.getDestroyElement(add_elem)

      if (id_elem?.value && _destroy_elem) {
        _destroy_elem.value = 1
        add_elem.style.display = 'none'
      } else {
        add_elem.remove()
      }
      const view_elements = this.add_elemTargets.filter((add_element) => {
        const destroy_element = this.getDestroyElement(add_element)
        // 削除フラグを持たない項目の場合は常にtrueを返し、表示項目として換算する
        return destroy_element ? destroy_element.value === '0' : true
      })
      if (view_elements.length <= 1 && !this.isDeleteLastElem) {
        this.destroy_buttonTargets.forEach((element) => {
          element.style.display = 'none'
        })
      }
    },
    settingDefaultValue(element) {
      const add_elem = this.getAddElement(element)
      const id_elem = this.getIdElement(add_elem)
      const _destroy_elem = this.getDestroyElement(add_elem)

      if (id_elem) {
        id_elem.value = null
      }
      if (_destroy_elem) {
        _destroy_elem.value = 0
      }
      if (add_elem.style.display === 'none') {
        add_elem.style.display = this.addDisplayStyle
      }
    },
    getAddElement(element) {
      return element.closest(`[${this.addElemAttribute}='add_elem']`)
    },
    getIdElement(element) {
      return element.querySelector('input[name$="[][id]"]') || element.querySelector('input[name$="[id][]"]')
    },
    getDestroyElement(element) {
      return (
        element.querySelector('input[name$="[][_destroy]"]') || element.querySelector('input[name$="[_destroy][]"]')
      )
    },
    removeValidation(elements) {
      this.removeElementsClass(elements, 'c-form-input--error')
      this.removeElements(elements, 'c-form-input--error__message')
      // field_with_errorsはdivタグとして囲っているため、class削除だけだとデザインが崩れるので
      // <div class="field_with_errors"></div>だけを削除するようにする
      Array.from(elements.getElementsByClassName('field_with_errors')).forEach((element) => {
        this.unWrap(element)
      })
    },
    removeElementsClass(elements, className) {
      if (elements.classList.contains(className)) {
        elements.classList.remove(className)
      }
      Array.from(elements.getElementsByClassName(className)).forEach((element) => {
        element.classList.remove(className)
      })
    },
    removeElements(elements, className) {
      Array.from(elements.getElementsByClassName(className)).forEach((element) => {
        element.remove()
      })
    },
    unWrap(element) {
      while (element.firstChild) {
        element.parentNode.insertBefore(element.firstChild, element)
      }
      element.remove()
    },
  })
}
