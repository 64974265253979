import { Controller } from 'stimulus'

/**
 * 医師情報編集のコントローラ
 * FYI: app/views/doctor_details/edit.html.slim
 */
export default class extends Controller {
  static targets = ['practitioner', 'employee', 'openYear']

  connect() {
    this.toggleOpenYear()
  }

  /**
   * 開業年の活性/非活性
   */
  toggleOpenYear() {
    if (this.employeeTarget.checked) {
      this.openYearTarget.hidden = true
    } else {
      this.openYearTarget.hidden = false
    }
  }
}
