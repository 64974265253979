import { Controller } from 'stimulus'
import * as bootstrap from 'bootstrap'

export default class extends Controller {
  static targets = ['toast_elem']

  connect() {
    new bootstrap.Toast(this.toast_elemTarget, { delay: 5000 }).show()
  }
}
