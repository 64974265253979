import { Controller } from 'stimulus'

/**
 * ページ遷移時のスクロール (現在は左右スクロールのみ対応)
 *
 * - params
 *   - data-scroll-parent ... スクロール値を設定する親要素
 *   - data-scroll-child  ... この子要素まで親要素をスクロールさせる
 *   - data-scroll-offset ... オフセット値 px (optional)
 *
 * - e.g. ページ遷移時に`child2`までスクロールさせる
 *   #controller[
 *     data-controller='scroll'
 *     data-scroll-parent='.js-parent'
 *     data-scroll-child=".js-child-2"
 *   ]
 *     ul.js-parent
 *       li.js-child-1 child1
 *       li.js-child-2 child2
 *       li.js-child-3 child3
 */
export default class extends Controller {
  connect() {
    this.controller = $(this.element)
    this.parent = this.controller.find(this.controller.data('scroll-parent'))
    this.child = this.controller.find(this.controller.data('scroll-child'))
    this.offset = parseInt(this.controller.data('scroll-offset')) || 0

    this.scrollLeft()
  }

  scrollLeft() {
    const current = this.parent.scrollLeft()
    const offset = this.child.offset()
    this.parent.scrollLeft(current + offset.left + this.offset)
  }
}
