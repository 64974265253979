import { Controller } from 'stimulus'
import $ from 'jquery'

require('jquery-datetimepicker/build/jquery.datetimepicker.full.min')

/**
 * 検索ページファセット用コントローラー
 */
export default class extends Controller {
  static targets = ['periodForm', 'periodStart', 'periodEnd']

  connect() {
    // options.i18n.jaを使う
    $.datetimepicker.setLocale('ja')

    // options.i18n.jaを定義
    const options = {
      i18n: {
        ja: {
          months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          dayOfWeek: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
        },
      },
      timepicker: false,
      format: 'Y/m/d',
    }

    this.update(options)
  }

  update(options) {
    const self = this

    $(this.periodStartTarget).datetimepicker(options)

    $(this.periodEndTarget).datetimepicker(
      Object.assign({}, options, {
        onShow: function () {
          this.setOptions({
            minDate: self.periodStartTarget.value || false,
          })
        },
      })
    )
  }

  /* 期間の入力フォームを表示する */
  showPeriodForm(_event) {
    $(this.periodFormTarget).show()
  }

  periodApply(event) {
    // 開始日が終了日より後の日付を選択された場合
    // 終了日が開始日と同じ日付に設定される
    if (
      this.periodStartTarget.value &&
      this.periodEndTarget.value &&
      this.periodEndTarget.value < this.periodStartTarget.value
    ) {
      this.periodEndTarget.value = this.periodStartTarget.value
    }

    const url = new URL(location)

    // クエリパラメータの生成
    if (this.periodStartTarget.value) {
      url.searchParams.set(this.periodStartTarget.name, this.periodStartTarget.value)
    } else {
      url.searchParams.delete(this.periodStartTarget.name)
    }

    if (this.periodEndTarget.value) {
      url.searchParams.set(this.periodEndTarget.name, this.periodEndTarget.value)
    } else {
      url.searchParams.delete(this.periodEndTarget.name)
    }
    url.searchParams.delete($(event.target).data('resetFor'))

    location.href = url.toString()
  }
}
