import { Controller } from 'stimulus'
import $ from 'jquery'

/**
 * 従業員一覧csvアップロードのコントローラ
 * FYI: app/views/pharma_admin/partner_users/index.html.slim
 */
export default class extends Controller {
  static targets = ['csvForm', 'csvUploadError', 'csvFileField']

  connect() {
    this.csvUploadError = $(this.csvUploadErrorTarget)
  }

  FileValidateAndUpload(_event) {
    // スピナー
    $(document).ajaxSend(function () {
      $('.overlay').fadeIn(300)
    })

    // /pharma_admin/partner_users にPOST
    const formattedCsv = new FormData(this.csvFormTarget)
    const self = this
    const header = { 'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content }
    $.ajax({
      data: formattedCsv,
      type: 'POST',
      url: '/pharma_admin/partner_users/csv_upload',
      cache: false,
      contentType: false,
      processData: false,
      headers: header,
      success: function (data) {
        self.handleResponse(data)
      },
    }).done(function () {
      setTimeout(function () {
        $('.overlay').fadeOut(300)
      }, 500)
    })
  }

  appendError(messages) {
    this.csvUploadError.empty()
    messages.forEach((message) => this.csvUploadError.append(`<li>${message}</li>`))
    this.csvUploadError.show()
  }

  handleResponse(data) {
    if (data.success) {
      window.location.href = '/pharma_admin/partner_users' + '?success=true'
    } else {
      $(this.csvFileFieldTarget).val('')
      this.appendError(data.error.messages)
    }
  }
}
