import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['headerWrapper', 'header']
  connect() {
    // SPレイアウト時の検索ボックス追従処理
    let $headerWrap = $(this.headerWrapperTarget)
    let $header = $(this.headerTarget)

    $(window).on('load scroll', () => {
      // SP width以外は処理を行わない
      if ($(window).width() > 999.98) return

      // input position sticky処理
      // 54px以上スクロールしたら処理
      if ($(window).scrollTop() > 54) {
        $headerWrap.addClass('l-header-search--wrapper--sticky')
        $header.addClass('l-header-search--sticky')
      } else {
        $headerWrap.removeClass('l-header-search--wrapper--sticky')
        $header.removeClass('l-header-search--sticky')
      }
    })
  }
}
