import { Controller } from 'stimulus'
import $ from 'jquery'

require('summernote/dist/summernote-bs4.js')
require('summernote/dist/summernote-bs4.css')

export default class extends Controller {
  initialize() {
    $('[data-editor="original-content-body-summernote"]').removeAttr('required max min maxlength pattern')
  }

  connect() {
    let smallStyle = function (context) {
      let smallButton = $.summernote.ui.button({
        contents: 'small',
        tooltip: 'small',
        click: function () {
          let text = context.invoke('editor.getSelectedText')
          let rng = context.invoke('editor.getLastRange')
          if (text) {
            let $node = $('<small>' + text + '</small>')
            context.invoke('editor.insertNode', $node[0])
            rng.normalize()
            rng.deleteContents()
          }
        },
      })
      return smallButton.render()
    }

    $('[data-editor="original-content-body-summernote"]').summernote({
      toolbar: prepareToolbar(),
      styleTags: ['p', 'h2', 'h3', 'h4', { title: '引用', tag: 'blockquote', value: 'blockquote' }],
      height: 500,
      focus: true,
      lang: 'ja-JP',
      prettifyHtml: true,
      placeholder: '本文を入力してください（画像挿入はタイプ jpg, jpeg, png, heicのみ、サイズ 8MBまで）',
      callbacks: {
        onImageUpload: function (files) {
          for (let i = 0; i < files.length; i++) {
            sendFile(files[i], $(this))
          }
        },
      },
      buttons: {
        small: smallStyle,
      },
    })

    function sendFile(file, toSummernote) {
      let data = new FormData()
      data.append('body_image[image]', file)
      const header = { 'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content }
      $.ajax({
        data: data,
        type: 'POST',
        url: '/private/api/v1/admin/original_content_body_images',
        cache: false,
        contentType: false,
        processData: false,
        headers: header,
        success: function (data) {
          let img = document.createElement('IMG')
          img.src = data.url
          img.setAttribute('id', data.body_image_id)
          let $content = $(`<figure>${img.outerHTML}<figcaption>キャプションを入力してください</figcaption></figure>`)
          toSummernote.summernote('insertNode', $content[0])
        },
      })
    }

    function prepareToolbar() {
      return [
        ['style', ['style', 'bold', 'italic', 'underline']],
        ['font', ['superscript', 'subscript']],
        ['custom', ['small']],
        ['para', ['ul', 'ol']],
        ['insert', ['link', 'picture']],
      ]
    }
  }
}
