import { Controller } from 'stimulus'
import $ from 'jquery'

/**
 * 医師免許の確認が済んでいないユーザーに表示する記事本文のコントローラー
 */
export default class extends Controller {
  static values = {
    updateUrl: String,
  }
  static targets = ['bodySection', 'restrictedSection']

  static classes = ['inactive', 'limited']

  connect() {}

  show() {
    const self = this
    const headers = { 'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content }
    $.ajax({
      type: 'PATCH',
      url: this.updateUrlValue,
      contentType: 'application/json',
      headers: headers,
    })
      .done(function () {
        $(self.bodySectionTarget).removeClass(self.limitedClass)
        $(self.restrictedSectionTarget).addClass(self.inactiveClass)

        // 疾患記事用 アンカーリンクをアクティブに
        // SP
        $('.c-tab-list-nav-item-link--disabled').each(function () {
          $(this).removeClass('c-tab-list-nav-item-link--disabled')
          $(this).addClass('c-tab-list-nav-item-link')
        })
        // PC
        $('.c-search-option__item.disabled').each(function () {
          $(this).removeClass('disabled')
        })
      })
      .fail(function () {
        location.reload()
      })
  }
}
