import { Controller } from 'stimulus'

/**
 * 医師アンケートのコントローラ
 * FYI: app/views/doctor_details/_step2_fields.html.slim
 */
export default class extends Controller {
  static targets = ['referralFromPartner', 'partners', 'learnedFromOthers', 'others']

  connect() {
    // パートナー
    this.referralFromPartner = $(this.referralFromPartnerTarget)
    this.partners = $(this.partnersTarget)
    // その他
    this.learnedFromOthers = $(this.learnedFromOthersTarget)
    this.others = $(this.othersTarget)

    // コールバック設定
    this.referralFromPartner.on('change', (_e) => {
      this.updateReferralFromPartner()
    })
    this.learnedFromOthers.on('change', (_e) => {
      this.updateReferralFromOthers()
    })

    // 初期設定
    this.updateReferralFromPartner()
    this.updateReferralFromOthers()
  }

  updateReferralFromPartner() {
    if (this.referralFromPartner.prop('checked')) {
      this.partners.show()
    } else {
      this.partners.hide()
    }
  }

  updateReferralFromOthers() {
    const checked = this.learnedFromOthers.prop('checked')
    this.others.prop('disabled', !checked)
  }
}
