import { Controller } from 'stimulus'
import $ from 'jquery'

/**
 * 本人確認の申請完了時に表示されるモーダル
 * app/views/shared/_modal_doctor_identity_verification_completed.html.slim
 * 使用する場合、controllerで flash: { modal: 'doctor-identity-verification-completed' } を設定する
 */
export default class extends Controller {
  connect() {
    $('#modalDoctorIdentityVerificationCompleted').modal('show')
  }
}
