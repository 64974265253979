import { Controller } from 'stimulus'

// WARNING: ここでのjqueryの読み込みは不要
// (webpack-pluginで読み込んでいるものと別のインスタンスが返るのでcocoonのコールバックが呼ばれない)
// import $ from 'jquery'

/**
 * Cocoonの項目数のバリデーション
 *
 * - targets
 *   - data-cocoon-target: 'addAssociation' ... 追加ボタン
 *   - data-cocoon-target: 'removeAssociation' ... 削除ボタン
 *
 * - params
 *   - data-cocoon-minimum ... 最小数 (フォーム数がこの数になると削除ボタンが消える)
 *   - data-cocoon-maximum ... 最大数 (フォーム数がこの数になると追加ボタンが消える)
 *
 * - e.g.
 *   #parent[
 *     data-controller='cocoon'
 *     data-cocoon-minimum=1
 *     data-cocoon-maximum=3
 *   ]
 *     = f.fields_for :tasks do |task|
 *       = render 'task_fields', f: task
 *       .links
 *         = link_to_add_association 'add task', f, :tasks,
 *           'data-cocoon-target': 'addAssociation'
 *
 *   / task_fields.html.slim
 *   .nested-fields
 *     = f.text_field :description
 *     = link_to_remove_association "remove task", f,
 *       'data-cocoon-target': 'removeAssociation'
 */
export default class extends Controller {
  static targets = [
    'addAssociation',
    // removeAssociationは動的なので別途jQueryで取得
    // 'removeAssociation'
  ]

  connect() {
    this.parent = $(this.element)
    this.minimum = parseInt(this.parent.data('cocoon-minimum'))
    this.maximum = parseInt(this.parent.data('cocoon-maximum'))
    this.addAssociation = $(this.addAssociationTarget)

    // 初期化
    this.updateAssociations()

    // コールバック設定
    //
    // - before-xxx系について
    //   制限に引っかかる場合はボタンを非表示にしているはずなので
    //   before-xxx系は基本的にはなくても問題はないが、フェイルセーフのために用意してある
    this.parent
      .on('cocoon:before-remove', (e, _item) => {
        if (!this.isItemMinimum()) {
          return
        }

        // 削除禁止
        e.preventDefault()
      })
      .on('cocoon:before-insert', (e, _item) => {
        if (!this.isItemMaximum()) {
          return
        }

        // 追加禁止
        e.preventDefault()
      })
      .on('cocoon:after-remove', () => {
        this.updateAssociations()
      })
      .on('cocoon:after-insert', () => {
        this.updateAssociations()
      })
  }

  /**
   * 項目追加・削除時の更新処理
   *
   * TODO: ここで追加・削除ボタンの表示切り替えをしているが
   *       そもそも表示のON/OFFでUI的にいいのか
   */
  updateAssociations() {
    // 削除系
    if (this.isItemMinimum()) {
      // 削除禁止
      this.removeAssociations.hide()
    } else {
      // 削除許可
      this.removeAssociations.show()
    }

    // 追加系
    if (this.isItemMaximum()) {
      // 追加禁止
      this.addAssociation.hide()
    } else {
      // 追加許可
      this.addAssociation.show()
    }
  }

  /**
   * 項目数を取得
   * @return {number}
   */
  itemLength() {
    return this.parent.find('.nested-fields:visible').length
  }

  /**
   * 項目数が下限か
   * @return {boolean}
   */
  isItemMinimum() {
    const itemLength = this.itemLength()
    return !Number.isNaN(this.minimum) && itemLength <= this.minimum
  }

  /**
   * 項目数が上限か
   * @return {boolean}
   */
  isItemMaximum() {
    const itemLength = this.itemLength()
    return !Number.isNaN(this.maximum) && itemLength >= this.maximum
  }

  /**
   * 削除ボタンを取得
   * @return {Object} jQueryオブジェクト
   */
  get removeAssociations() {
    return this.parent.find('.nested-fields *[data-cocoon-target="removeAssociation"]')
  }
}
