import { Controller } from 'stimulus'
import $ from 'jquery'

require('jquery-datetimepicker/build/jquery.datetimepicker.full.min')

/**
 * データエクスチェンジ一覧のコントローラ
 */
export default class extends Controller {
  static values = {
    files: Object,
    downloadUrl: String,
  }
  static targets = ['year', 'month', 'list']
  connect() {
    this.updatemonth()
  }

  // 月のプルダウンを更新
  updatemonth() {
    const year = this.yearTarget.value
    const $target = $(this.monthTarget)
    $target.empty()
    for (let m = 1; m <= 12; m++) {
      m = this.pad(m)
      let option = $('<option>').val(m).text(m)
      if (!this.filesValue[year][m]) {
        option.prop('disabled', true)
      }
      $target.append(option)
    }

    // 現在月のデータがあれば選択状態にしておく
    const thisMonth = this.pad(new Date().getMonth() + 1)
    if (!$(`option[value="${thisMonth}"]`, $target).prop('disabled')) {
      $target.val(thisMonth)
    }
    this.updateday()
  }

  // 日付のリストを更新
  updateday() {
    const year = this.yearTarget.value
    const month = this.monthTarget.value
    const $target = $(this.listTarget)

    $target.empty()
    let url = new URL(this.downloadUrlValue, location)
    url.searchParams.set('y', year)
    url.searchParams.set('m', month)
    for (let d = 1; d <= new Date(year, month, 0).getDate(); d++) {
      d = this.pad(d)
      let link = $('<a>').text(d).addClass('btn mr-2 mb-2')

      if (this.filesValue[year][month].includes(d)) {
        url.searchParams.set('d', d)
        link.attr('href', url).addClass('btn-outline-primary')
      } else {
        link.addClass('btn-outline-secondary disabled')
      }
      $target.append(link)
    }
  }

  pad(num) {
    return num.toString().padStart(2, '0')
  }
}
