import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  connect() {
    let current_datetime = new Date()

    $(window).on('load', function () {
      if (!document.cookie.split('; ').includes('registered=true')) {
        $('#confirmationModal').modal('show')
      }
    })

    $('#doctor-modal-btn').click(function () {
      $('#confirmationModal').modal('hide')

      // cookie保存（有効期限１日）
      current_datetime.setTime(current_datetime.getTime() + 24 * 60 * 60 * 1000)
      document.cookie = 'registered=true; expires=' + current_datetime.toGMTString()
    })
  }
}
