import { Controller } from 'stimulus'
import $ from 'jquery'

require('summernote/dist/summernote-bs4.js')
require('summernote/dist/summernote-bs4.css')

export default class extends Controller {
  static targets = ['editor']

  connect() {
    this.editor = $(this.editorTarget)
    this.initializeSummernote()
  }

  initializeSummernote() {
    $(this.editor).summernote({
      toolbar: this.prepareToolbar(),
      styleTags: ['p', 'h2', 'h3', 'h4'],
      height: 500,
      lang: 'ja-JP',
      prettifyHtml: true,
      placeholder: '本文を入力してください',
      callbacks: {
        onImageUpload: function (files) {
          for (let i = 0; i < files.length; i++) {
            this.sendFile(files[i], $(this))
          }
        },
      },
    })
    if ($(this.editor).attr('data-disabled') == 'true') {
      $(this.editor).summernote('disable')
    }
    $(this.editor).removeAttr('required max min maxlength pattern')
  }

  sendFile(file, toSummernote) {
    let data = new FormData()
    data.append('body_image[image]', file)
    const header = { 'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content }
    $.ajax({
      data: data,
      type: 'POST',
      url: '/private/api/v1/admin/original_content_body_images',
      cache: false,
      contentType: false,
      processData: false,
      headers: header,
      success: function (data) {
        let img = document.createElement('IMG')
        img.src = data.url
        img.setAttribute('id', data.body_image_id)
        toSummernote.summernote('insertNode', img)
      },
    })
  }

  prepareToolbar() {
    let result_array = [
      ['style', ['style', 'bold']],
      ['para', ['ul', 'ol']],
      ['insert', ['link']],
    ]

    return result_array
  }
}
