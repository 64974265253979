import { Controller } from 'stimulus'
import $ from 'jquery'

// typeahead
import Bloodhound from '../../typeahead/bloodhound'

/**
 * Senbeiの疾患タグのコントローラ
 * FYI: app/views/admin/original_contents/_original_content_senbei_tag_fields.html.slim
 */
export default class extends Controller {
  static targets = ['senbeiTagName', 'senbeiTagCode']

  connect() {
    this.senbeiTagName = $(this.senbeiTagNameTarget)
    this.initializeSenbeiTag()
  }

  /**
   * Senbeiの疾患タグクエリ用のBloodhoundを作成
   */
  initializeSenbeiTag() {
    // データ設定
    const bloodhound = new Bloodhound({
      // この辺りはローカルデータを設定・分割するためなので適当でOKです
      // 設定しないとエラーが出るので適当に設定しておく必要があります
      // => jquery3.source.js:321 Uncaught Error: datumTokenizer is required
      local: [],
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      // リモート設定 (ajax設定)
      remote: {
        url: '/private/api/v1/admin/senbei_tags/search',
        prepare: (query, settings) => {
          settings.type = 'GET'
          settings.contentType = 'application/json'
          settings.data = {
            name: query,
          }
          return settings
        },
        // 検索し出すまでの待機時間 (ms)
        rateLimitWait: 200,
      },
    })

    // サジェスト設定
    this.senbeiTagName
      .typeahead(
        {
          // n文字以上になったらサジェストを行う
          minLength: 1,
          // 一致する語句をハイライト(太字)にする
          highlight: true,
          // ヒントを非表示
          hint: false,
        },
        {
          // レスポンスの`name`キーのリストをサジェストリストとして利用する
          displayKey: 'name',
          limit: 1000,
          source: bloodhound,
          templates: {
            header: '<div class="tt-header"疾患タグ名候補</div>',
          },
        }
      )
      .on('typeahead:selected typeahead:autocomplete', (e, senbei_tag) => {
        // 選択肢が選ばれたときは、dataに情報を埋め込む
        this.senbeiTagName.attr('data-selected-code', senbei_tag.code)
        this.senbeiTagName.data('selected-code', senbei_tag.code)
        this.senbeiTagName.attr('data-selected-name', senbei_tag.name)
        this.senbeiTagName.data('selected-name', senbei_tag.name)
        this.senbeiTagNameTarget.classList.remove('border-danger')
        this.senbeiTagCodeTarget.value = senbei_tag.code
      })
  }

  onSenbeiTagFieldChanged() {
    this.senbeiTagNameTarget.classList.add('border-danger')
    this.senbeiTagCodeTarget.value = null
  }
}
