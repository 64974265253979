import { Controller } from 'stimulus'
import $ from 'jquery'

// typeahead
import Bloodhound from '../../typeahead/bloodhound'

/**
 * 医師の勤務先情報のコントローラ
 * FYI: app/views/doctor_details/_original_content_author_fields.html.slim
 */
export default class extends Controller {
  static targets = ['fullName', 'candyDoctorCode', 'image', 'removeImage', 'position', 'descriptionHtml', 'link']

  connect() {
    this.candyDoctorCode = $(this.candyDoctorCodeTarget)
    this.fullName = $(this.fullNameTarget)
    this.position = $(this.positionTarget)
    this.image = $(this.imageTarget)
    this.removeImage = $(this.removeImageTarget)
    this.descriptionHtml = $(this.descriptionHtmlTarget)
    this.link = $(this.linkTarget)

    // CKEditorはCKEditor側で非表示にしてこちらでは変更しない
    this.initializeAuthor()
    this.updateLink(this.candyDoctorCode.val())
    this.displayLink()
  }

  /**
   * candy医師コードクエリ用のBloodhoundを作成
   */

  initializeAuthor() {
    // データ設定
    const bloodhound = new Bloodhound({
      // この辺りはローカルデータを設定・分割するためなので適当でOKです
      // 設定しないとエラーが出るので適当に設定しておく必要があります
      // => jquery3.source.js:321 Uncaught Error: datumTokenizer is required
      local: [],
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      // リモート設定 (ajax設定)
      remote: {
        url: '/private/api/v1/admin/candy_doctors/search',
        prepare: (query, settings) => {
          settings.type = 'GET'
          settings.contentType = 'application/json'
          settings.data = {
            token: this.activationToken,
            name: query,
          }
          return settings
        },
        // 検索し出すまでの待機時間 (ms)
        rateLimitWait: 200,
      },
    })

    // サジェスト設定
    this.fullName
      .typeahead(
        {
          // n文字以上になったらサジェストを行う
          minLength: 1,
          // 一致する語句をハイライト(太字)にする
          highlight: true,
          // ヒントを非表示
          hint: false,
        },
        {
          // レスポンスの`name`キーのリストをサジェストリストとして利用する
          displayKey: 'name',
          limit: 1000,
          source: bloodhound,
          templates: {
            header: '<div class="tt-header">candy医師候補</div>',
          },
        }
      )
      .on('typeahead:selected typeahead:autocomplete', (e, doctor) => {
        // 選択肢が選ばれたときは、dataに情報を埋め込む
        this.fullName.attr('data-selected-candy-doctor-code', doctor.code)
        this.fullName.data('selected-candy-doctor-code', doctor.code)

        this.fullName.attr('data-selected-full-name', doctor.name)
        this.fullName.data('selected-full-name', doctor.name)
        this.onAuthorNameChanged()
      })
      .on('change', (_e) => {
        this.onAuthorNameChanged()
      })
  }

  /**
   *candy医師情報のクリア
   */
  clearAuthor({ skipNameValue = false } = {}) {
    this.candyDoctorCode.val('')
    if (!skipNameValue) {
      this.fullName.val('')
    }
    this.candyDoctorCodeTarget.value = null
    this.position.prop('disabled', false)
    this.position.prop('readonly', false)
    this.image.prop('disabled', false)
    this.removeImage.prop('checked', false)
    this.removeImage.attr('onclick', '')
    this.descriptionHtml.summernote('enable')

    this.updateLink('')
    this.displayLink()
  }

  /**
   * candy医師情報による更新処理
   */
  onAuthorNameChanged() {
    // inputのテキストとdataのテキストを比較して変更されたかチェック
    const text = this.fullName.val()
    const selectedName = this.fullName.data('selected-full-name')
    const selectedCode = this.fullName.data('selected-candy-doctor-code')

    if (text === selectedName) {
      // テキストが同じであればcodeに書き込む
      this.candyDoctorCode.val(selectedCode)

      // 医師コードと名前、敬称以外のformをリセット
      this.position.val(null)
      this.removeImage.prop('checked', true)
      this.descriptionHtml.summernote('reset')

      // candy医師コードが入ったら自動入力のため入力できないようにする
      this.position.prop('readonly', true)
      this.image.prop('disabled', true)
      this.removeImage.attr('onclick', 'return false;')
      this.descriptionHtml.summernote('disable')

      // candy管理画面へのlinkを出す
      this.updateLink(selectedCode)
      this.displayLink()
    } else {
      // テキストが違う場合は、任意入力なので`candyDoctorCode`とdataをクリア
      this.clearAuthor({ skipNameValue: true })
    }
  }
  onFullNameChanged() {
    this.clearAuthor({ skipNameValue: true })
  }

  updateLink(selectedCode) {
    let linkBase = $('#candy_admin_link_dev').data('candy-admin-link')
    let linkURL = `${linkBase}${selectedCode}`
    this.link.attr('href', linkURL)
  }

  displayLink() {
    if (this.candyDoctorCode.val().length) {
      this.link.parents('.candy_admin_link_dev').css('display', '')
    } else {
      this.link.parents('.candy_admin_link_dev').css('display', 'none')
    }
  }
}
