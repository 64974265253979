import { Controller } from 'stimulus'
import $ from 'jquery'

// typeahead
import Bloodhound from '../../typeahead/bloodhound'

/**
 * Senbeiの疾患タグのコントローラ
 * FYI: app/views/admin/original_contents/_original_content_icd_fields.html.slim
 */
export default class extends Controller {
  static targets = ['icdDpcVersion', 'icdCode']

  connect() {
    this.icdCode = $(this.icdCodeTarget)
    this.icdDpcVersion = $(this.icdDpcVersionTarget)
    this.initializeicd()
  }

  /**
   * Senbeiの疾患タグクエリ用のBloodhoundを作成
   */
  initializeicd() {
    // データ設定
    const bloodhound = new Bloodhound({
      // この辺りはローカルデータを設定・分割するためなので適当でOKです
      // 設定しないとエラーが出るので適当に設定しておく必要があります
      // => jquery3.source.js:321 Uncaught Error: datumTokenizer is required
      local: [],
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      // リモート設定 (ajax設定)
      remote: {
        url: '/private/api/v1/admin/icds/search',
        prepare: (query, settings) => {
          settings.type = 'GET'
          settings.contentType = 'application/json'
          settings.data = {
            dpc_version: this.icdDpcVersionTarget.value,
            code: query,
          }
          return settings
        },
        // 検索し出すまでの待機時間 (ms)
        rateLimitWait: 200,
      },
    })

    // サジェスト設定
    this.icdCode
      .typeahead(
        {
          // n文字以上になったらサジェストを行う
          minLength: 1,
          // 一致する語句をハイライト(太字)にする
          highlight: true,
          // ヒントを非表示
          hint: false,
        },
        {
          // レスポンスの`name`キーのリストをサジェストリストとして利用する
          displayKey: 'code',
          limit: 1000,
          source: bloodhound,
          templates: {
            header: '<div class="tt-header">ICD-10候補</div>',
            suggestion: function (data) {
              return '<div>' + data.code + ' - ' + data.name + '</div>'
            },
          },
        }
      )
      .on('typeahead:selected typeahead:autocomplete', (e, icd) => {
        // 選択肢が選ばれたときは、dataに情報を埋め込む
        this.icdCode.attr('data-selected-code', icd.code)
        this.icdCode.data('selected-code', icd.code)
        this.icdDpcVersion.attr('data-selected-dpc-version', icd.dpcVersion)
        this.icdDpcVersion.data('selected-dpc-version', icd.dpcVersion)
        this.icdCodeTarget.classList.remove('border-danger')
      })
  }

  onicdFieldChanged() {
    this.icdCodeTarget.classList.add('border-danger')
  }

  ondpcSelected() {
    this.icdCodeTarget.value = null
  }
}
