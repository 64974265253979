import { Controller } from 'stimulus'
import $ from 'jquery'

/**
 * ハンバーガーメニュー
 */
export default class extends Controller {
  connect() {
    $(function () {
      // モーダルオープン
      $('.js-hamburger-menu-open').on('click', function () {
        $('.c-hamburger-menu').fadeIn(400).addClass('is-open')
      })
      $('.js-hamburger-menu-close').on('click', function () {
        $('.c-hamburger-menu').fadeOut(400).removeClass('is-open')
      })
      $(window).on('load resize', function () {
        let winW = $(window).width()
        let devW = 999
        if (winW <= devW) return

        $('.c-hamburger-menu').removeAttr('style')
      })
    })
  }
}
