import { Controller } from 'stimulus'
import $ from 'jquery'

// typeahead
import Bloodhound from '../../typeahead/bloodhound'

/**
 * 医師の勤務先情報のコントローラ
 * FYI: app/views/doctor_details/_doctor_institution_fields.html.slim
 * 修正の際、app/javascript/controllers/admin/users/doctors/institution_fields_controller.js
 * に似たような記載があるので注意
 */

export default class extends Controller {
  static targets = [
    'prefectureId',
    'prefectureIdWrapper',
    'institutionName',
    'freeInputInstitutionName',
    'institutionCode',
    'institutionAddress',
    'institutionNameWrapper',
    'institutionAddressWrapper',
    'workplaceKind',
    'badgeRequired',
    'badgeAny',
    'affiliationAndPositionWrapper',
    'doctorNote',
    'freeInputInstitutionNameDoctorNote',
    'doctorSupplement',
    'institutionNameFormDev',
    'affiliation',
    'position',
  ]
  static values = {
    institutionCodeNotValid: String,
  }

  connect() {
    this.activationToken = $(this.element).data('token')
    this.prefectureId = $(this.prefectureIdTarget)
    this.institutionCode = $(this.institutionCodeTarget)
    this.institutionName = $(this.institutionNameTarget)
    this.freeInputInstitutionName = $(this.freeInputInstitutionNameTarget)
    this.institutionAddress = $(this.institutionAddressTarget)
    // 所属部科、役職・大学職位の入力欄がない時は宣言しない
    if (this.hasPositionTarget) {
      this.affiliation = $(this.affiliationTarget)
      this.position = $(this.positionTarget)
    }

    this.initializeInstitution()
    this.changeWorkPlaceKind({ initialize: true })
  }

  /**
   * 勤務状況が変更されたとき
   */
  changeWorkPlaceKind({ initialize = false }) {
    const skipValue = initialize
    switch (this.workplaceKindTarget.value) {
      case '':
        this.prefectureIdWrapperTarget.hidden = true
        this.institutionNameWrapperTarget.hidden = true
        this.institutionAddressWrapperTarget.hidden = true
        this.affiliationAndPositionWrapperTarget.hidden = true
        break
      case 'domestic_hospital_or_clinic':
        // 初回読み込み以外はformをリセットする
        this.clearInstitution({
          skipCodeValue: skipValue,
          skipNameValue: skipValue,
          skipAddressValue: skipValue,
          skipPrefectureIdValue: skipValue,
          skipPositionValueAndAffiliationValue: skipValue,
        })
        this.changeWorkPlaceKindUi(true)
        // 勤務先に関するformが二つあるので、
        // 基本的にはinstitutionName（選択）のdisabledをつける、freeInputInstitutionName（自由入力）のdisableを外す
        if (initialize && this.prefectureId.val()) {
          let name1FormDisabled = this.institutionName.val() === this.institutionCodeNotValidValue
          this.institutionName.prop('disabled', name1FormDisabled)
          this.freeInputInstitutionName.prop('disabled', !name1FormDisabled)

          this.institutionAddressWrapperTarget.hidden = !name1FormDisabled

          this.institutionAddress.prop('readonly', false)
          this.institutionAddress.prop('disabled', false)
        } else {
          this.institutionName.prop('disabled', true)
          this.freeInputInstitutionName.prop('disabled', false)
          // 勤務先名２（自由入力）と住所のformを隠す
          this.institutionAddressWrapperTarget.hidden = true
          // 住所を入力できないようにする
          this.institutionAddress.prop('readonly', true)
          this.institutionAddress.prop('disabled', true)
        }

        break
      case 'foreign_hospital_or_company':
        // 初回読み込み以外はformをリセットする
        this.clearInstitution({
          skipNameValue: skipValue,
          skipAddressValue: skipValue,
          skipPrefectureIdValue: skipValue,
          skipPositionValueAndAffiliationValue: skipValue,
        })
        this.changeWorkPlaceKindUi(false)
        // 勤務先名２（自由入力）と住所のformを出す
        this.institutionAddressWrapperTarget.hidden = false

        // 勤務先に関するformが二つあるので、
        // 基本的にはinstitutionName（選択）のdisabledをつける、freeInputInstitutionName（自由入力）のdisableを外す
        this.institutionName.prop('disabled', true)
        this.freeInputInstitutionName.prop('disabled', false)

        // 住所を入力できるようにする
        this.institutionAddress.prop('readonly', false)
        this.institutionAddress.prop('disabled', false)
        break
      case 'none':
        // 初回読み込み以外はformをリセットする
        this.clearInstitution({
          skipNameValue: skipValue,
          skipAddressValue: skipValue,
          skipPrefectureIdValue: skipValue,
          skipPositionValueAndAffiliationValue: skipValue,
        })

        this.prefectureIdWrapperTarget.hidden = true
        this.institutionNameWrapperTarget.hidden = true
        this.institutionAddressWrapperTarget.hidden = true
        this.affiliationAndPositionWrapperTarget.hidden = true
        break
    }
  }

  /**
   * 勤務地クエリ用のBloodhoundを作成
   */
  initializeInstitution() {
    // データ設定
    const bloodhound = new Bloodhound({
      // この辺りはローカルデータを設定・分割するためなので適当でOKです
      // 設定しないとエラーが出るので適当に設定しておく必要があります
      // => jquery3.source.js:321 Uncaught Error: datumTokenizer is required
      local: [],
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      // リモート設定 (ajax設定)
      remote: {
        url: '/private/api/v1/institutions/search',
        prepare: (query, settings) => {
          settings.type = 'GET'
          settings.contentType = 'application/json'
          settings.data = {
            prefecture_id: this.prefectureId.val(),
            token: this.activationToken,
            name: query,
          }
          return settings
        },
        // 検索し出すまでの待機時間 (ms)
        rateLimitWait: 200,
      },
    })

    // サジェスト設定
    this.institutionName
      .typeahead(
        {
          // n文字以上になったらサジェストを行う
          minLength: 1,
          // 一致する語句をハイライト(太字)にする
          highlight: true,
          // ヒントを非表示
          hint: false,
        },
        {
          // レスポンスの`name`キーのリストをサジェストリストとして利用する
          displayKey: 'name',
          limit: 1000,
          source: bloodhound,
          templates: {
            header: '<div class="tt-header">勤務先名候補</div>',
            suggestion: function (data) {
              let address = data.address ? '（' + data.address + '）' : ''
              return '<div>' + data.name + address + '</div>'
            },
          },
        }
      )
      .on('typeahead:selected typeahead:autocomplete', (e, institution) => {
        // 選択肢が選ばれたときは、dataに情報を埋め込む
        this.institutionName.attr('data-selected-code', institution.code)
        this.institutionName.data('selected-code', institution.code)
        this.institutionName.attr('data-selected-name', institution.name)
        this.institutionName.data('selected-name', institution.name)
        this.institutionName.attr('data-selected-address', institution.address)
        this.institutionName.data('selected-address', institution.address)
        this.onInstitutionChanged()
      })
      .on('change', (_e) => {
        this.onInstitutionChanged()
      })
      .on('blur', (_e) => {
        const text = this.institutionName.val()
        const selectedName = this.institutionName.data('selected-name')
        if (text != this.institutionCodeNotValidValue && text != '' && selectedName == '') {
          // サジェストされた候補を何も選択していない状態でformを離れたら、
          // form内容とサジェスト情報も一度リセットする
          this.institutionName.val('')
          this.institutionName.attr('data-selected-name', '')

          this.institutionAddress.val('')
          this.institutionName.attr('data-selected-address', '')
          this.institutionName.data('selected-address', '')

          this.institutionName.typeahead('val', '')
        }
      })
  }

  /**
   * 勤務地のクリア
   */
  clearInstitution({
    skipCodeValue = false,
    skipNameValue = false,
    skipAddressValue = false,
    skipPrefectureIdValue = false,
    skipPositionValueAndAffiliationValue = false,
  } = {}) {
    if (!skipCodeValue) {
      this.institutionCode.val('')
    }
    if (!skipPrefectureIdValue) {
      this.prefectureId.val('')
    }
    if (!skipNameValue) {
      this.institutionName.val('')
      this.institutionName.prop('disabled', !this.prefectureId.val())
      this.institutionName.prop('readonly', !this.prefectureId.val())

      this.freeInputInstitutionName.prop('disabled', false)
      this.freeInputInstitutionName.val('')
      this.freeInputInstitutionName.prop('disabled', true)
      this.institutionName.typeahead('val', '')
    }
    this.institutionName.attr('data-selected-code', '')
    this.institutionName.data('selected-code', '')
    this.institutionName.attr('data-selected-name', '')
    this.institutionName.data('selected-name', '')
    this.institutionName.attr('data-selected-address', '')
    this.institutionName.data('selected-address', '')
    if (!skipAddressValue) {
      this.institutionAddress.val('')
      this.institutionAddress.prop('readonly', true)
      this.institutionAddress.prop('disabled', true)
    }
    // 所属部科、役職・大学職位の入力欄がない時は宣言されていないので処理しない
    if (!skipPositionValueAndAffiliationValue && this.position != undefined) {
      this.position.val('')
      this.affiliation.val('')
    }
  }

  changeWorkPlaceKindUi(workplaceKindIsDomesticHospitalOrClinic) {
    const display = workplaceKindIsDomesticHospitalOrClinic ? '' : 'none'
    // 都道府県選択項目の表示/非表示
    this.prefectureIdWrapperTarget.hidden = !workplaceKindIsDomesticHospitalOrClinic
    // 所属部科/役職の非表示
    this.affiliationAndPositionWrapperTarget.hidden = false

    // ※admin/users/doctors/institution_fields_controller.jsとの違いは以下※
    // 勤務先名formの非表示
    this.institutionNameWrapperTarget.hidden = false
    // 注意書き類と勤務先名のform１（選択）を表示/非表示
    this.doctorNoteTarget.style.display = display
    this.freeInputInstitutionNameDoctorNoteTarget.style.display = display
    this.doctorSupplementTarget.style.display = display
    this.institutionNameFormDevTarget.style.display = display

    // 医師・歯科医師・薬剤師の時のみ必須と任意の出し分け
    if (this.hasBadgeRequiredTarget) {
      // 勤務先住所のバッチを必須に付け替える
      this.badgeRequiredTarget.style.display = display
      // 勤務先住所のバッチを任意に付け替える（国外医師は勤務先住所が任意のため）
      this.badgeAnyTarget.style.display = workplaceKindIsDomesticHospitalOrClinic ? 'none' : ''
    }

    // css調整
    if (workplaceKindIsDomesticHospitalOrClinic) {
      this.freeInputInstitutionName.prop('placeholder', '○○クリニック')
      this.institutionAddress.prop('placeholder', '都道府県以降の住所を入力してください')
      this.institutionNameWrapperTarget.classList.add('p-form-set')
    } else {
      this.freeInputInstitutionName.prop('placeholder', '')
      this.institutionAddress.prop('placeholder', '')
      this.institutionNameWrapperTarget.classList.remove('p-form-set')
    }
    // ※admin/users/doctors/institution_fields_controller.jsとの違いは以上※
  }

  /**
   * 勤務地・勤務地住所の更新処理
   */
  onInstitutionChanged() {
    // inputのテキストとdataのテキストを比較して変更されたかチェック
    const text = this.institutionName.val()
    const selectedName = this.institutionName.data('selected-name')

    // 選択候補に出ないを選択した場合
    if (text === selectedName && selectedName == this.institutionCodeNotValidValue) {
      //勤務先名form（自由入力）と勤務先住所のformを出す
      this.institutionAddressWrapperTarget.hidden = false

      // テキストが違う場合は、自由入力なので`institution_code`とdataをクリア
      this.clearInstitution({
        skipNameValue: true,
        skipAddressValue: true,
        skipPrefectureIdValue: true,
        skipPositionValueAndAffiliationValue: true,
      })

      // `institution_address`を入力できるようにする
      this.institutionAddress.val('')
      this.institutionAddress.prop('readonly', false)
      this.institutionAddress.prop('disabled', false)

      // 勤務先に関するformが二つあるので、
      // institutionNameのdisabledをつける、freeInputInstitutionNameのdisableを外す
      this.freeInputInstitutionName.val('')
      this.institutionName.prop('disabled', true)
      this.freeInputInstitutionName.prop('disabled', false)
    } else if (text === selectedName) {
      // 候補に出たdonutsの病院を選択した場合
      // `institution_code`,`institution_address`に書き込む
      const selectedCode = this.institutionName.data('selected-code')
      const selectedAddress = this.institutionName.data('selected-address')
      this.institutionCode.val(selectedCode)
      this.freeInputInstitutionName.val(selectedName)
      this.institutionAddress.val(selectedAddress)

      // 勤務先に関するformが二つあるので、
      // institutionNameのdisabledを外す、freeInputInstitutionNameのdisableをつける
      this.institutionName.prop('disabled', false)
      this.freeInputInstitutionName.prop('disabled', true)

      // `institution_address`は自動入力のため入力できないようにする
      this.institutionAddress.prop('readonly', true)
      this.institutionAddress.prop('disabled', true)
    } else {
      // テキストが違う場合は、任意入力なので`institution_code`とdataをクリア
      this.clearInstitution({
        skipNameValue: true,
        skipAddressValue: true,
        skipPrefectureIdValue: true,
        skipPositionValueAndAffiliationValue: true,
      })
      // `institution_address`を入力できるようにする
      this.institutionAddress.prop('readonly', false)
      this.institutionAddress.prop('disabled', false)
    }
  }

  /**
   * 都道府県変更のコールバック
   */
  onPrefectureIdChanged() {
    this.clearInstitution({
      skipNameValue: false,
      skipAddressValue: true,
      skipPrefectureIdValue: true,
      skipPositionValueAndAffiliationValue: true,
    })
    this.institutionAddressWrapperTarget.hidden = true
  }
}
