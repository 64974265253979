import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['publication_button']

  initialize() {
    const targetElement = this.publication_buttonTarget

    window.addEventListener('scroll', () => {
      let scrollY = window.scrollY
      if (scrollY >= 200) {
        targetElement.classList.add('active')
      } else {
        targetElement.classList.remove('active')
      }
    })
  }
}
