import { Controller } from 'stimulus'
// import TomSelect from "tom-select"

export default class extends Controller {
  initialize(_event) {
    const _config = {
      valueField: 'name',
      labelField: 'name',
      searchField: [],
      maxOptions: null,
      highlight: false,
      loadThrottle: 300,
      load: function (query, callback) {
        const url = '/api/suggest/leopard_universities?name=' + encodeURIComponent(query)

        fetch(url)
          .then((response) => {
            return response.json()
          })
          .then((json) => {
            this.clear()
            this.clearOptions()
            this.refreshOptions()

            // suggest の結果がない場合は入力したテキストを選択できるようにする
            if (json.length == 0) {
              json = [{ name: query }]
            }

            callback(json)
          })
          .catch(() => {
            callback()
          })
      },
    }
    // new TomSelect(this.element, config);
  }
}
