import { Controller } from 'stimulus'
import $ from 'jquery'

/**
 * SSO許諾画面のコントローラ
 * FYI: app/views/saml_idp/idp/confirm.html.slim
 */
export default class extends Controller {
  static targets = ['profileContent', 'profileKind', 'doctorContent', 'doctorKind']

  /**
   * プロフィール情報の開閉
   */
  toggleProfile() {
    if (this.profileContentTarget.hidden == true) {
      this.profileContentTarget.hidden = false
    } else {
      this.profileContentTarget.hidden = true
    }
    $(this.profileKindTarget).toggleClass('arrow-up')
    $(this.profileKindTarget).toggleClass('arrow-down')
  }

  /**
   * 医師情報の開閉
   */
  toggleDoctor() {
    if (this.doctorContentTarget.hidden == true) {
      this.doctorContentTarget.hidden = false
    } else {
      this.doctorContentTarget.hidden = true
    }
    $(this.doctorKindTarget).toggleClass('arrow-up')
    $(this.doctorKindTarget).toggleClass('arrow-down')
  }
}
