import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['examinationFree', 'prescriptionRight']

  connect() {
    if (this.examinationFreeTarget.checked) {
      this.prescriptionRightTarget.disabled = false
    } else {
      this.prescriptionRightTarget.disabled = true
    }
  }

  onExaminationFreeChanged() {
    if (this.examinationFreeTarget.checked) {
      this.prescriptionRightTarget.disabled = false
    } else {
      this.prescriptionRightTarget.disabled = true
      this.prescriptionRightTarget.checked = false
    }
  }
}
