import { Controller } from 'stimulus'

/**
 * admin 独自記事編集画面のコントローラ
 * FYI: app/views/admin/original_contents/_form.html.slim
 */
export default class extends Controller {
  static targets = ['selectKind', 'diseaseField']

  connect() {
    this.toggleDiseaseField()
  }

  onKindSelected() {
    this.toggleDiseaseField()
  }

  toggleDiseaseField() {
    if ($(this.selectKindTarget).val() == 'disease') {
      this.diseaseFieldTarget.hidden = false
    } else {
      this.diseaseFieldTarget.hidden = true
    }
  }
}
