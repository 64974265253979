import { Controller } from 'stimulus'
import $ from 'jquery'

/**
 * 電場番号認証コード確認フォームのコントローラ
 * FYI: app/views/doctor_identity_verification_requests/phone_number_confirm.html.slim
 */
export default class extends Controller {
  static targets = ['regenerateConfirmationCodeSuccessMessage', 'regenerateConfirmationCodeErrorMessage']

  regenerateConfirmationCode(event) {
    // /private/api/v1/doctor_identity_verification_requests/confirmation_code にPOST
    const currentUserId = $(event.target).data('current-user-id')
    const phoneNumber = $(event.target).data('phone-number')
    const self = this

    $.ajax({
      data: { doctor_id: currentUserId, phone_number: phoneNumber },
      type: 'POST',
      url: '/private/api/v1/doctor_identity_verification_requests/confirmation_code',
      cache: false,
      success: function (data) {
        self.handleResponse(data)
      },
    })
  }

  handleResponse(data) {
    if (data.success) {
      this.showSuccessMessage()
    } else {
      this.appendErrorMessage(data.error.message)
    }
  }

  showSuccessMessage() {
    const message = $(this.regenerateConfirmationCodeSuccessMessageTarget)
    message.show()

    setTimeout(() => {
      message.hide()
    }, 5000)
  }

  appendErrorMessage(message) {
    const messageWrapper = $(this.regenerateConfirmationCodeErrorMessageTarget)
    messageWrapper.append(`<h4 class="alert-heading">${message}</h4>`)
    messageWrapper.show()

    setTimeout(() => {
      messageWrapper.hide()
      messageWrapper.empty()
    }, 5000)
  }
}
