import { Controller } from 'stimulus'
import {
  overwriteCloneSearchSelectElement,
  clearClonedDataSearchSelectElement,
  overwriteCloneSuggestSelectElement,
} from 'controllers/doctor_profiles//utils/nested_form'
import { multiPart } from 'controllers/doctor_profiles/mixins/multi_part'

export default class extends Controller {
  static targets = ['append_elem', 'add_elem', 'destroy_button']

  connect() {
    if (this.add_elemTargets.length > 1) {
      this.destroy_buttonTargets.forEach((element) => {
        element.style.display = 'flex'
      })
    }

    multiPart(this)
    this.initializeView('data-doctor-profiles--shared--nested-form-target')
  }

  addAssociation(event) {
    event.preventDefault()

    const clonedAddItem = this.add_elemTarget.cloneNode(true)

    clonedAddItem.querySelectorAll('[data-doctor-profiles--shared--nested-form-type]').forEach((element) => {
      const inputType = element.getAttribute('data-doctor-profiles--shared--nested-form-type')
      const inputDefaultValue = element.getAttribute('data-doctor-profiles--shared--nested-form-default-value')

      if (inputType == 'text') {
        element.value = inputDefaultValue
      } else if (inputType == 'select') {
        element.querySelector(`option[value="${inputDefaultValue}"]`).selected = true
      } else if (inputType == 'search_select') {
        overwriteCloneSearchSelectElement(element)
        clearClonedDataSearchSelectElement(clonedAddItem)
      } else if (inputType == 'suggest_select') {
        const suggestInput = element.querySelector('[data-doctor-profiles--shared--nested-form-sugegst-select="input"]')
        const hiddenCode = element.querySelector('[data-doctor-profiles--shared--nested-form-suggest-select="code"]')
        overwriteCloneSuggestSelectElement(suggestInput, hiddenCode, [
          'doctor-profiles--shared--nested-form-suggest-result-code',
          'doctor-profiles--shared--nested-form-suggest-result-name',
        ])
      }
    })

    // 複製時にバリデーションエラーを表示させないようにする処理
    this.removeValidation(clonedAddItem)

    this.append_elemTarget.appendChild(clonedAddItem)

    if (this.add_elemTargets.length > 1) {
      this.destroy_buttonTargets.forEach((element) => {
        element.style.display = 'flex'
      })
    }

    this.settingDefaultValue(clonedAddItem)
  }
}
