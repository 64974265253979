import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['preview']

  /**
   * 画像が選択されたとき、プレビューを表示する
   */
  changeImage(event) {
    const target = event.target
    const preview = this.previewTarget
    if (target.files && target.files[0]) {
      const reader = new FileReader()
      reader.onload = function () {
        preview.src = this.result
      }
      reader.readAsDataURL(target.files[0])
    }
  }
}
