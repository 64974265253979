import { Controller } from 'stimulus'
import $ from 'jquery'

require('summernote/dist/summernote-bs4.js')
require('summernote/dist/summernote-bs4.css')

export default class extends Controller {
  initialize() {
    $('[data-editor="pharma-inquiry-setting-disclaimer-text-for-partner-users-summernote"]').removeAttr(
      'required max min maxlength pattern'
    )
  }

  connect() {
    $('[data-editor="pharma-inquiry-setting-disclaimer-text-for-partner-users-summernote"]').summernote({
      toolbar: prepareToolbar(),
      height: 150,
      lang: 'ja-JP',
      prettifyHtml: true,
      placeholder: '本文を入力してください',
    })

    function prepareToolbar() {
      let result_array = [
        ['style', ['bold']],
        ['insert', ['link']],
      ]

      return result_array
    }
  }
}
