import { Controller } from 'stimulus'
import $ from 'jquery'

/**
 * 従業員画像一覧のコントローラ
 * FYI: app/views/pharma_admin/partner_user_images/index.html.slim
 */
export default class extends Controller {
  static targets = ['imageForm', 'imageUploadError', 'imageFileField']

  connect() {
    this.imageUploadError = $(this.imageUploadErrorTarget)
  }

  FileValidateAndUpload(event) {
    const imageFile = event.target.files[0]

    // zipファイル名のチェック
    if (imageFile.name != 'image.zip') {
      this.appendError(['zipファイル名は「image.zip」にしてください'])
      $(this.imageFileFieldTarget).val('')
      return
    }

    // zipファイルサイズのチェック max 1GB
    if (imageFile.size > 1000000000) {
      this.appendError(['zipファイルサイズは1GB未満にしてください'])
      $(this.imageFileFieldTarget).val('')
      return
    }

    // スピナー
    $(document).ajaxSend(function () {
      $('.overlay').fadeIn(300)
    })

    // /pharma_admin/partner_user_images にPOST
    const formattedImage = new FormData(this.imageFormTarget)
    const self = this
    const header = { 'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content }
    $.ajax({
      data: formattedImage,
      type: 'POST',
      url: '/pharma_admin/partner_user_images',
      cache: false,
      contentType: false,
      processData: false,
      headers: header,
      success: function (data) {
        self.handleResponse(data)
      },
    }).done(function () {
      setTimeout(function () {
        $('.overlay').fadeOut(300)
      }, 500)
    })
  }

  appendError(messages) {
    this.imageUploadError.empty()
    messages.forEach((message) => this.imageUploadError.append(`<li>${message}</li>`))
    this.imageUploadError.show()
  }

  handleResponse(data) {
    if (data.success) {
      window.location.href = '/pharma_admin/partner_user_images' + '?success=true'
    } else {
      $(this.imageFileFieldTarget).val('')
      this.appendError(data.error.messages)
    }
  }
}
