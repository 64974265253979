import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['mhlwVerification', 'phoneVerification', 'rejectedReason']

  connect() {
    this.changeRejectedReason()
  }

  changeRejectedReason() {
    if (this.mhlwVerificationTarget.value == 'rejected' || this.phoneVerificationTarget.value == 'rejected') {
      this.rejectedReasonTarget.disabled = false
    } else {
      this.rejectedReasonTarget.disabled = true
    }
  }
}
