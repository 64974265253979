import { Controller } from 'stimulus'
import { multiPart } from 'controllers/doctor_profiles/mixins/multi_part'
import { overwriteCloneSuggestSelectElement } from 'controllers/doctor_profiles/utils/nested_form'

export default class extends Controller {
  static targets = ['append_elem', 'add_elem', 'destroy_button', 'unfixed_destroy_button']

  connect() {
    if (this.add_elemTargets.length > 1) {
      this.unfixed_destroy_buttonTargets.forEach((element) => {
        if (element.getAttribute('doctor-profiles--skills-editable') == 'true') {
          element.style.display = 'flex'
        }
      })
    }

    multiPart(this)
    this.initializeView('data-doctor-profiles--skills-target')
  }

  addAssociation(event) {
    event.preventDefault()

    const clonedAddItem = this.add_elemTarget.cloneNode(true)
    const inputDisease = clonedAddItem.querySelector('[data-doctor-profiles--skills-form="disease"]')
    const hiddenCodeInput = clonedAddItem.querySelector('[data-doctor-profiles--skills-hidden="hidden_code"]')
    const destroyButton = clonedAddItem.querySelector('[data-doctor-profiles--skills-target="destroy_button"]')
    const hiddenIdInput = clonedAddItem.querySelector('[data-doctor-profiles--skills-hidden="hidden_id"]')

    overwriteCloneSuggestSelectElement(inputDisease, hiddenCodeInput, [
      'doctor-profiles--shared--suggest-diseases-disease-code',
      'doctor-profiles--shared--suggest-diseases-disease-name',
    ])

    destroyButton.style.display = 'flex'
    destroyButton.setAttribute('doctor-profiles--skills-editable', 'true')

    hiddenIdInput.value = ''

    this.append_elemTarget.appendChild(clonedAddItem)

    if (this.add_elemTargets.length > 1) {
      this.destroy_buttonTargets.forEach((element) => {
        if (element.getAttribute('data-doctor-profiles--skills-editable') == 'true') {
          element.style.display = 'flex'
        }
      })
    }
    this.settingDefaultValue(clonedAddItem)
    // 複製時にバリデーションエラーを表示させないようにする処理
    this.removeValidation(clonedAddItem)
  }
}
