import { Controller } from 'stimulus'

/**
 * パスワードフィールドの表示・非表示切り替え
 *
 * e.g.
 *   .c-form-password[data-controller='password-field']
 *     = f.password_field :password, class: 'form-control c-form-control'
 *     = link_to '', 'javascript:void(0)', class: 'c-form-password__visible-button'
 *
 */
export default class extends Controller {
  connect() {
    this.parent = $(this.element)
    this.formControl = this.parent.find('.form-control')
    this.visibleButton = this.parent.find('.c-form-password__visible-button')

    this.visibleButton.click(() => {
      this.onVisibleButtonClicked()
    })
  }

  onVisibleButtonClicked() {
    if (this.formControl.attr('type') === 'password') {
      this.formControl.attr('type', 'text')
      this.visibleButton.addClass('show')
    } else {
      this.formControl.attr('type', 'password')
      this.visibleButton.removeClass('show')
    }
  }
}
